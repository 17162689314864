<template>
    <!-- Mobile -->
  <div class="mobile">
    <div class="row-flex text-align-start title-services-container" style="margin-top: 60px;">
        <h1 style="margin: 0;">Servicios</h1>
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-briefcase-fill" viewBox="0 0 16 16">
            <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v1.384l7.614 2.03a1.5 1.5 0 0 0 .772 0L16 5.884V4.5A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5z"/>
            <path d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5V6.85L8.129 8.947a.5.5 0 0 1-.258 0L0 6.85v5.65z"/>
        </svg>
    </div>
    <div class="column-flex padding">
        <button class="list-container" id="tile1">
            <div class="row-flex" style="margin: auto; width: 100%;" @click="toggleTile('arrow1', 0, '330px')">
              <span class="material-icons">lightbulb</span>
              <div class="column-flex list-container-text">
                  <h4 style="margin: 0;">Creativos</h4>
                  <p>Creación y desarrollo de ideas para tu marca.</p>
              </div>
              <span class="material-icons material-symbols-altern" id="arrow1">expand_more</span>
            </div>
            <!-- hidden elements -->
            <div class="panel" id="panel1">
              <!-- Diseño Gráfico -->
              <router-link to="/creativos#graphic-design" style="text-decoration: none">
                <div class="row-flex" style="margin: 10px 0">
                  <span class="material-icons" style="font-size: 20px; margin-left: 5px;">design_services</span>
                  <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                    <h5>Diseño Gráfico</h5>
                    <p style="color: white;">Ir a página</p>
                  </div>
                </div>
              </router-link>
              <!-- Editorial -->
              <router-link to="/creativos#editorials" style="text-decoration: none">
                <div class="row-flex" style="margin: 10px 0">
                  <span class="material-icons" style="font-size: 20px; margin-left: 5px;">feed</span>
                  <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                    <h5>Editorial</h5>
                    <p style="color: white;">Ir a página</p>
                  </div>
                </div>
              </router-link>
              <!-- Ilustración -->
              <router-link to="/creativos#illustrations" style="text-decoration: none">
                <div class="row-flex" style="margin: 10px 0">
                  <span class="material-icons" style="font-size: 20px; margin-left: 5px;">palette</span>
                  <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                    <h5>Ilustración</h5>
                    <p style="color: white;">Ir a página</p>
                  </div>
                </div>
              </router-link>
              <!-- Branding -->
              <router-link to="/creativos#branding" style="text-decoration: none">
                <div class="row-flex" style="margin: 10px 0">
                  <span class="material-icons" style="font-size: 20px; margin-left: 5px;">drive_file_rename_outline</span>
                  <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                    <h5>Branding</h5>
                    <p style="color: white;">Ir a página</p>
                  </div>
                </div>
              </router-link>
            </div>
        </button>
        <div class="divider"></div>
        <button class="list-container" @click="toggleTile('arrow2', 1, '500px')">
          <div class="row-flex" style="margin: auto; width: 100%;">
            <span class="material-icons">trending_up</span>
            <div class="column-flex list-container-text">
                <h4 style="margin: 0;">Mercadeo</h4>
                <p>Campañas y estrategias para promocionar tu marca.</p>
            </div>
            <span class="material-icons material-symbols-altern" id="arrow2">expand_more</span>
          </div>
          <!-- hidden elements -->
          <div class="panel" id="panel1">
            <!-- Asesorías -->
            <router-link to="/mercadeo" style="text-decoration: none">
              <div class="row-flex" style="margin: 10px 0">
                <span class="material-icons" style="font-size: 20px; margin-left: 5px;">support_agent</span>
                <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                  <h5>Asesorías</h5>
                  <p style="color: white">Ir a página</p>
                </div>
              </div>
            </router-link>
            <!-- Intercambios -->
            <router-link to="/mercadeo" style="text-decoration: none">
              <div class="row-flex" style="margin: 10px 0">
                <span class="material-icons" style="font-size: 20px; margin-left: 5px;">handshake</span>
                <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                  <h5>Intercambios</h5>
                  <p style="color: white">Ir a página</p>
                </div>
              </div>
            </router-link>
            <!-- Material P.O.P -->
            <router-link to="/mercadeo" style="text-decoration: none">
              <div class="row-flex" style="margin: 10px 0">
                <span class="material-icons" style="font-size: 20px; margin-left: 5px;">branding_watermark</span>
                <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                  <h5>Material P.O.P</h5>
                  <p style="color: white">Ir a página</p>
                </div>
              </div>
            </router-link>
            <!-- Litografía -->
            <router-link to="/mercadeo" style="text-decoration: none">
              <div class="row-flex" style="margin: 10px 0">
                <span class="material-icons" style="font-size: 20px; margin-left: 5px;">brush</span>
                <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                  <h5>Litografía</h5>
                  <p style="color: white">Ir a página</p>
                </div>
              </div>
            </router-link>
            <!-- Publicidad Exterior -->
            <router-link to="/mercadeo" style="text-decoration: none">
              <div class="row-flex" style="margin: 10px 0">
                <span class="material-icons" style="font-size: 20px; margin-left: 5px;">travel_explore</span>
                <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                  <h5>Publicidad Exterior</h5>
                  <p style="color: white">Ir a página</p>
                </div>
              </div>
            </router-link>
            <!-- Eventos -->
            <router-link to="/mercadeo" style="text-decoration: none">
              <div class="row-flex" style="margin: 10px 0">
                <span class="material-icons" style="font-size: 20px; margin-left: 5px;">celebration</span>
                <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                  <h5>Eventos</h5>
                  <p style="color: white">Ir a página</p>
                </div>
              </div>
            </router-link>
            <!-- Promotores -->
            <router-link to="/mercadeo" style="text-decoration: none">
              <div class="row-flex" style="margin: 10px 0">
                <span class="material-icons" style="font-size: 20px; margin-left: 5px;">badge</span>
                <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                  <h5>Promotores</h5>
                  <p style="color: white">Ir a página</p>
                </div>
              </div>
            </router-link>
          </div>
        </button>
        <div class="divider"></div>
        <button class="list-container" @click="toggleTile('arrow3', 2, '280px')">
          <div class="row-flex" style="margin: auto; width: 100%;">
            <span class="material-icons">group</span>
            <div class="column-flex list-container-text">
                <h4 style="margin: 0;">Social Media</h4>
                <p>Manejo y gestión de redes sociales.</p>
            </div>
            <span class="material-icons material-symbols-altern" id="arrow3">expand_more</span>
          </div>
          <!-- hidden elements -->
          <div class="panel" id="panel1">
            <!-- Audiencia -->
            <router-link to="/social-media" style="text-decoration: none">
              <div class="row-flex" style="margin: 10px 0">
                <span class="material-icons" style="font-size: 20px; margin-left: 5px;">group</span>
                <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                  <h5>Audiencia</h5>
                  <p style="color: white">Ir a página</p>
                </div>
              </div>
            </router-link>
            <!-- Portafolio -->
            <router-link to="/social-media" style="text-decoration: none">
              <div class="row-flex" style="margin: 10px 0">
                <span class="material-icons" style="font-size: 20px; margin-left: 5px;">grid_view</span>
                <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                  <h5>Portafolio</h5>
                  <p style="color: white">Ir a página</p>
                </div>
              </div>
            </router-link>
            <!-- Objetivos -->
            <router-link to="/social-media" style="text-decoration: none">
              <div class="row-flex" style="margin: 10px 0">
                <span class="material-icons" style="font-size: 20px; margin-left: 5px;">assignment</span>
                <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                  <h5>Objetivos</h5>
                  <p style="color: white">Ir a página</p>
                </div>
              </div>
            </router-link>
          </div>
        </button>
        <div class="divider"></div>
        <button class="list-container" @click="toggleTile('arrow4', 3, '250px')">
          <div class="row-flex" style="margin: auto; width: 100%;">
            <span class="material-icons">devices</span>
            <div class="column-flex list-container-text">
                <h4 style="margin: 0;">Tecnologías</h4>
                <p style="margin: 0;">Desarrollo de páginas web y aplicaciones.</p>
            </div>
            <span class="material-icons material-symbols-altern" id="arrow4">expand_more</span>
          </div>
          <!-- hidden elements -->
          <div class="panel" id="panel1">
            <!-- Proyectos -->
            <router-link to="/tecnologias" style="text-decoration: none">
              <div class="row-flex" style="margin: 10px 0">
                <span class="material-icons" style="font-size: 20px; margin-left: 5px;">developer_mode</span>
                <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                  <h5>Proyectos</h5>
                  <p style="color: white">Ir a página</p>
                </div>
              </div>
            </router-link>
            <!-- Desarrollo Web -->
            <router-link to="/tecnologias" style="text-decoration: none">
              <div class="row-flex" style="margin: 10px 0">
                <span class="material-icons" style="font-size: 20px; margin-left: 5px;">web</span>
                <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                  <h5>Desarrollo Web</h5>
                  <p style="color: white">Ir a página</p>
                </div>
              </div>
            </router-link>
            <!-- SEO -->
            <router-link to="/tecnologias" style="text-decoration: none">
              <div class="row-flex" style="margin: 10px 0">
                <span class="material-icons" style="font-size: 20px; margin-left: 5px;">language</span>
                <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                  <h5>SEO</h5>
                  <p style="color: white">Ir a página</p>
                </div>
              </div>
            </router-link>
          </div>
        </button>
        <button class="list-container" @click="toggleTile('arrow5', 4, '250px')">
          <div class="row-flex" style="margin: auto; width: 100%;">
            <span class="material-icons">camera</span>
            <div class="column-flex list-container-text">
              <h4 style="margin: 0;">Bestway Studio</h4>
              <p style="margin: 0;">Departamento de contenido audiovisual.</p>
            </div>
            <span class="material-icons material-symbols-altern" id="arrow5">expand_more</span>
          </div>
          <!-- hidden elements -->
          <div class="panel" id="panel1">
            <!-- Bestway Photography -->
            <div class="row-flex" style="margin: 10px 0">
              <span class="material-icons" style="font-size: 20px; margin-left: 5px;">grid_view</span>
              <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                <h5>Portafolio</h5>
                <p style="color: white;">Ir a página</p>
              </div>
            </div>
            <!-- Fotografía -->
            <div class="row-flex" style="margin: 10px 0">
              <span class="material-icons" style="font-size: 20px; margin-left: 5px;">photo_camera</span>
              <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                <h5>Fotografía</h5>
                <p style="color: white;">Ir a página</p>
              </div>
            </div>
            <!-- Audiovisual -->
            <div class="row-flex" style="margin: 10px 0">
              <span class="material-icons" style="font-size: 20px; margin-left: 5px;">videocam</span>
              <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                <h5>Audiovisual</h5>
                <p style="color: white;">Ir a página</p>
              </div>
            </div>
          </div>
        </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServiciosMobile',
  methods: {
    toggleTile: function (id, index, height) {
      console.log('Toggle event')
      const arrow = document.getElementById(id)
      const tile = document.getElementsByClassName('list-container')
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        tile[index].style.height = height
        tile[index].style.backgroundImage = 'linear-gradient(130deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.05))'
        tile[index].style.transition = 'all 0.3s linear'
        arrow.style.transform = 'rotate(180deg)'
      } else {
        tile[index].style.height = '70px'
        tile[index].style.backgroundImage = 'linear-gradient(130deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))'
        tile[index].style.transition = '0.0s'
        arrow.style.transform = 'rotate(0deg)'
      }
    }
  }
}
</script>

<style>
  .title-services-container {
      margin: 30px;
  }
  .text-align-start {
      text-align: left;
  }
  .row-flex {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
  }
  .padding {
      padding: 20px;
  }
  .column-flex {
      display: flex;
      flex-direction: column;
  }
  .column-flex h5 {
    font-size: 14px;
    margin: 0;
  }
  .column-flex-height {
    height: 70vh;
    max-height: 80vh;
  }
  .panel p {
    margin: 5px 0;
  }
  .list-container {
      height: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      margin: 5px 0;
      color: #282828;
      background-color: rgba(64, 84, 71, 0);
      border-style: none;
      border-radius: 10px;
      transition: all 0.3s ease-in;
      overflow: hidden;
  }
  .list-container h4 {
    color: #2c3e50;
    font-size: 16px;
  }
  .panel {
    text-align: left;
    padding: 10px 20px;
    width: 100%;
  }
  .panel h5 {
    color: #f5f5f5;
    margin: 2px 0;
  }
  .list-container-text {
      text-align: left;
      width: 70%;
      color: white;
  }
  .mobile .material-icons {
      color: #f5f5f5;
      font-size: 30px;
      transition: 0.2s linear;
      font-variation-settings:
      'FILL' 0,
      'wght' 400,
      'GRAD' 0,
      'opsz' 48
  }
  .material-symbols-altern {
      color: #282828;
  }
</style>
