<template>
<div class="intro-container" id="nosotros-opacity">
      <div class="info-title">
      <h1 style="color: white;">BestFamily</h1>
      <p class="subtitle">
          Somos un grupo de creativos que formamos una familia. <br>
          Aquí nos miramos a los ojos, nos reímos de todo y almorzamos juntos. <br>
          Las donas y el café no pueden faltar.
      </p>
  </div>
  <div class="teams-icons">
      <!-- <h3 class="teams-title" > TEAMS</h3> -->
      <div class="nosotros-column-icons">
        <div class="icon-container" @click="scrollToCreators">
            <svg width="95%" height="95%" viewBox="0 0 378 456"  fill="currentColor" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M282.218 257.555C297.734 237.095 305.935 212.674 305.935 186.931C305.935 155.368 293.556 125.78 271.078 103.617C248.604 81.459 218.817 69.493 187.25 69.949C156.659 70.382 127.838 82.657 106.096 104.513C84.355 126.368 72.229 155.254 71.951 185.848C71.713 212.076 79.962 236.936 95.806 257.742C121.527 291.518 135.693 332.891 135.693 374.24V419.484C135.693 439.553 152.02 455.88 172.089 455.88H205.943C226.012 455.88 242.339 439.552 242.339 419.484V374.243C242.34 332.437 256.03 292.086 282.218 257.555ZM107.74 248.655C93.932 230.523 86.743 208.852 86.95 185.985C87.449 131.05 132.538 85.725 187.462 84.948C215.015 84.578 240.955 94.983 260.546 114.3C280.143 133.621 290.935 159.416 290.935 186.932C290.935 209.374 283.788 230.661 270.266 248.492C242.673 284.877 227.816 327.325 227.208 371.422H150.672C150.045 327.753 134.855 284.261 107.74 248.655ZM150.693 414.401V408.329L227.34 417.11V419.484C227.34 420.734 227.21 421.952 227.004 423.143L150.693 414.401ZM150.693 393.231V386.422H227.34V402.012L150.693 393.231ZM172.089 440.881C164.032 440.881 157.007 436.401 153.358 429.804L217.925 437.201C214.504 439.522 210.38 440.88 205.944 440.88H172.089V440.881Z" fill="currentColor"/>
                <path d="M114.776 198.213C114.191 194.288 113.912 190.256 113.949 186.23C113.987 182.088 110.66 178.7 106.518 178.662C102.404 178.626 98.988 181.951 98.95 186.093C98.906 190.903 99.239 195.725 99.94 200.426C100.495 204.148 103.695 206.821 107.349 206.821C107.717 206.821 108.09 206.794 108.465 206.738C112.561 206.126 115.387 202.31 114.776 198.213Z" fill="currentColor"/>
                <path d="M158.913 312.135C159.689 312.135 160.479 312.013 161.257 311.758C165.192 310.464 167.333 306.225 166.039 302.291C157.727 277.014 145.339 253.464 129.219 232.297C126.555 228.798 124.194 225.071 122.203 221.218C120.301 217.538 115.776 216.098 112.096 218C108.417 219.902 106.976 224.427 108.878 228.107C111.268 232.729 114.096 237.196 117.286 241.385C132.392 261.221 144.001 283.289 151.79 306.976C152.828 310.133 155.761 312.135 158.913 312.135Z" fill="currentColor"/>
                <path d="M267.746 212.613C263.942 210.974 259.529 212.73 257.891 216.534C255.515 222.052 252.44 227.315 248.752 232.177C246.249 235.477 246.896 240.182 250.196 242.685C251.551 243.713 253.143 244.209 254.722 244.209C256.989 244.209 259.229 243.186 260.704 241.24C265.123 235.413 268.811 229.097 271.667 222.468C273.306 218.664 271.551 214.251 267.746 212.613Z" fill="currentColor"/>
                <path d="M188.941 111.938C230.293 111.938 263.935 145.581 263.935 186.933C263.935 189.284 263.827 191.653 263.614 193.974C263.236 198.099 266.272 201.749 270.397 202.128C270.63 202.149 270.861 202.16 271.091 202.16C274.924 202.16 278.194 199.237 278.551 195.345C278.805 192.57 278.935 189.74 278.935 186.933C278.935 137.31 238.564 96.938 188.941 96.938C184.799 96.938 181.441 100.296 181.441 104.438C181.441 108.58 184.799 111.938 188.941 111.938Z" fill="currentColor"/>
                <path d="M188.941 57C193.083 57 196.441 53.642 196.441 49.5V7.5C196.441 3.358 193.083 0 188.941 0C184.799 0 181.441 3.358 181.441 7.5V49.5C181.441 53.642 184.799 57 188.941 57Z" fill="currentColor"/>
                <path d="M113.065 71.82C114.455 74.227 116.975 75.572 119.567 75.572C120.839 75.572 122.129 75.248 123.31 74.566C126.897 72.495 128.127 67.908 126.056 64.321L105.066 27.961C102.994 24.373 98.408 23.144 94.821 25.215C91.234 27.286 90.004 31.873 92.075 35.46L113.065 71.82Z" fill="currentColor"/>
                <path d="M349.22 272.115L312.86 251.125C309.272 249.053 304.685 250.283 302.615 253.871C300.544 257.458 301.773 262.045 305.361 264.116L341.721 285.106C342.902 285.788 344.191 286.112 345.464 286.112C348.056 286.112 350.577 284.766 351.966 282.36C354.037 278.773 352.807 274.186 349.22 272.115Z" fill="currentColor"/>
                <path d="M28.661 104.366L65.021 125.356C66.202 126.038 67.491 126.362 68.764 126.362C71.356 126.362 73.877 125.016 75.266 122.61C77.337 119.023 76.108 114.436 72.52 112.365L36.16 91.375C32.573 89.303 27.986 90.533 25.915 94.121C23.844 97.708 25.073 102.294 28.661 104.366Z" fill="currentColor"/>
                <path d="M369.68 180.74H327.68C323.538 180.74 320.18 184.098 320.18 188.24C320.18 192.382 323.538 195.74 327.68 195.74H369.68C373.822 195.74 377.18 192.382 377.18 188.24C377.18 184.098 373.823 180.74 369.68 180.74Z" fill="currentColor"/>
                <path d="M57.7 188.24C57.7 184.098 54.342 180.74 50.2 180.74H8.20001C4.05801 180.74 0.700012 184.098 0.700012 188.24C0.700012 192.382 4.05801 195.74 8.20001 195.74H50.2C54.343 195.74 57.7 192.382 57.7 188.24Z" fill="currentColor"/>
                <path d="M309.117 126.362C310.389 126.362 311.679 126.038 312.86 125.356L349.22 104.366C352.807 102.295 354.037 97.708 351.966 94.121C349.895 90.534 345.307 89.304 341.721 91.375L305.361 112.365C301.774 114.436 300.544 119.023 302.615 122.61C304.004 125.016 306.526 126.362 309.117 126.362Z" fill="currentColor"/>
                <path d="M65.021 251.125L28.661 272.115C25.074 274.186 23.844 278.773 25.915 282.36C27.304 284.766 29.825 286.112 32.417 286.112C33.689 286.112 34.979 285.788 36.16 285.106L72.52 264.116C76.107 262.045 77.337 257.458 75.266 253.871C73.196 250.283 68.606 249.054 65.021 251.125Z" fill="currentColor"/>
                <path d="M254.571 74.566C255.752 75.248 257.041 75.572 258.314 75.572C260.906 75.572 263.427 74.226 264.816 71.82L285.806 35.46C287.877 31.873 286.647 27.286 283.06 25.215C279.474 23.144 274.886 24.373 272.815 27.961L251.825 64.321C249.754 67.908 250.984 72.495 254.571 74.566Z" fill="currentColor"/>
            </svg>
          </div>
          <p class="nosotros-icon-label">Creativos</p>
      </div>
      <div class="nosotros-column-icons">
        <div class="icon-container" @click="scrollToDesigners">
          <svg xmlns="http://www.w3.org/2000/svg" width="95%" height="95%" fill="currentColor" class="bi bi-bezier" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M0 10.5A1.5 1.5 0 0 1 1.5 9h1A1.5 1.5 0 0 1 4 10.5v1A1.5 1.5 0 0 1 2.5 13h-1A1.5 1.5 0 0 1 0 11.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm10.5.5A1.5 1.5 0 0 1 13.5 9h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM6 4.5A1.5 1.5 0 0 1 7.5 3h1A1.5 1.5 0 0 1 10 4.5v1A1.5 1.5 0 0 1 8.5 7h-1A1.5 1.5 0 0 1 6 5.5v-1zM7.5 4a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z"/>
            <path d="M6 4.5H1.866a1 1 0 1 0 0 1h2.668A6.517 6.517 0 0 0 1.814 9H2.5c.123 0 .244.015.358.043a5.517 5.517 0 0 1 3.185-3.185A1.503 1.503 0 0 1 6 5.5v-1zm3.957 1.358A1.5 1.5 0 0 0 10 5.5v-1h4.134a1 1 0 1 1 0 1h-2.668a6.517 6.517 0 0 1 2.72 3.5H13.5c-.123 0-.243.015-.358.043a5.517 5.517 0 0 0-3.185-3.185z"/>
          </svg>
        </div>
        <p class="nosotros-icon-label">Diseñadores</p>
      </div>
      <!-- <div class="nosotros-column-icons">
        <div class="icon-container" @click="foregroundMockUp">
          <svg xmlns="http://www.w3.org/2000/svg" width="95%" height="95%" fill="currentColor" class="bi bi-person-workspace" viewBox="0 0 16 16">
            <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/>
            <path d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z"/>
          </svg>
            <p class="nosotros-icon-label">Project Managers</p>
        </div>
      </div> -->
      <div class="nosotros-column-icons">
        <div class="icon-container" @click="scrollToStudio">
          <svg xmlns="http://www.w3.org/2000/svg" width="95%" height="95%" fill="currentColor" class="bi bi-camera-fill" viewBox="0 0 16 16">
            <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
            <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z"/>
          </svg>
        </div>
        <p class="nosotros-icon-label">Studio</p>
      </div>
      <div class="nosotros-column-icons">
        <div class="icon-container" @click="scrollToTech">
          <svg xmlns="http://www.w3.org/2000/svg" width="95%" height="95%" fill="currentColor" class="bi bi-pc-display-horizontal" viewBox="0 0 16 16">
            <path d="M1.5 0A1.5 1.5 0 0 0 0 1.5v7A1.5 1.5 0 0 0 1.5 10H6v1H1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-5v-1h4.5A1.5 1.5 0 0 0 16 8.5v-7A1.5 1.5 0 0 0 14.5 0h-13Zm0 1h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5ZM12 12.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm2 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0ZM1.5 12h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1ZM1 14.25a.25.25 0 0 1 .25-.25h5.5a.25.25 0 1 1 0 .5h-5.5a.25.25 0 0 1-.25-.25Z"/>
          </svg> 
        </div>
        <p class="nosotros-icon-label">Tecnologias</p>
      </div>
  </div>

</div>
</template>

<script>
export default {
  name: 'NosotrosIntro',
  methods: {
    foregroundMockUp: function () {
      var mockup = document.getElementById('ig-container')
      mockup.classList.toggle('backgrounded')
      var background = document.getElementById('nosotros-opacity')
      var mainContainer = document.getElementById('main-container')
      // Show Column
      var nosotrosColumn = document.getElementById('nosotros-column-box');
      nosotrosColumn.classList.toggle('column-box-active');

      // Background
      background.classList.toggle('background-opacity');
      mainContainer.classList.toggle('table-background-zoom');

      // Reset scroll position
      var creators = document.getElementById('ig-creativos');
      creators.scrollIntoView(true);
    },
    scrollToCreators: function () {
      var creators = document.getElementById('ig-creativos');

      this.foregroundMockUp();
      creators.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
    scrollToDesigners: function () {
      var designers = document.getElementById('ig-designers');

      this.foregroundMockUp();
      designers.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
    scrollToStudio: function () {
      var studio = document.getElementById('ig-studio');

      this.foregroundMockUp();
      studio.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
    scrollToTech: function () {
      var tech = document.getElementById('ig-tecnologias');

      this.foregroundMockUp();
      tech.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
  }

}
</script>

<style>
.intro-container{
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    position: absolute;
    z-index: 100;
    height: 94%;
    width: 100%;
    opacity: 1;
    transition: ease-in-out 0.6s;
}

.nosotros-column-icons {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.nosotros-icon-label {
  color: white;
  font-weight: 600;
  text-align: center;
  margin: 0;
  margin-top: 10px;
}

.background-opacity {
  opacity: 0;
}

.info-title h1{
    font-size: 4.5rem;
    text-shadow: 2px 4px 6px #e3e3e39a;
    font-style: italic;
    letter-spacing: 5px;
}

.info-title .subtitle {
    font-size: 1.5rem;
    color: #fff;
    text-shadow: -2px 1px 3px #333;
}

.teams-icons{
    display: flex;
    justify-content: space-around;
    flex-flow: row wrap;
}

.teams-icons .title{
    width: 100%;
    font-size: 2em;
}

.icon-container{
    width: 6rem;
    height: 6rem;
    border-radius: 50% ;
    border: solid 2px rgb(132, 51, 238);
    color: #009a7e;
    padding: 1rem;
    box-sizing: border-box;
    transition: ease-in-out 0.3s;
}

.nosotros-icon-label {
  filter: drop-shadow(2px 4px 3px rgba(0,0,0, 0.5));
}

.icon-container svg {
  color: #009a7e;
}

.icon-container:hover {
  background-color: white;
}

@media (max-width: 760px){
  .intro-container{
    display: none;
  }
}
</style>
