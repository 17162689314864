<template>
  <NosotrosIntro />
  <NosotrosComponent/>
</template>

<script>
import NosotrosIntro from '@/components/nosotros/UsIntro.vue'
import NosotrosComponent from '@/components/nosotros/Nosotros.vue'

export default {
  name: 'Nosotros',
  components: {
    NosotrosIntro,
    NosotrosComponent
  },
  methods: {
    isMobile () {
      if (screen.width <= 760) {
        return true
      } else {
        return false
      }
    }
  },
  mounted () {
    var background = document.getElementById('main-container');
    background.classList.toggle('table-background')

    if (screen.width <= 760) {
      const nav = document.getElementById('navbar-mobile')
      nav.style.display = 'none'
    } else {
      const mock = document.getElementById('ig-container')
      mock.classList.add('backgrounded')
    }
  },
  beforeUnmount () {
    var background = document.getElementById('main-container');
    background.classList.toggle('table-background')
  }
}

</script>

<style>

</style>
