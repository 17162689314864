<template>
  <nav v-if="$route.path =='/creativos'" id="creatives-nav" class="team-nav glass-container" style="border-radius: initial;">
    <!-- path changed from /teams to /departamentos -->
      <router-link to="/departamentos" style="text-decoration: none; height: -webkit-fill-available; color: #2c3e50;" class="column-button icon-button-small">
        <div class="column-button">
          <span class="material-icons" style="margin-bottom: 5px;">arrow_back</span>
          Atras
        </div>
      </router-link>
      <a href="#graphic-design" style="text-decoration: none; height: -webkit-fill-available; color: #2c3e50;" class="column-button icon-button-small">
        <div class="column-button">
          <span class="material-icons" style="margin-bottom: 5px;">design_services</span>
          Diseño Grafico
        </div>
      </a>
      <a href="#editorials" style="text-decoration: none; height: -webkit-fill-available; color: #2c3e50;" class="column-button icon-button-small">
        <div class="column-button">
          <span class="material-icons" style="margin-bottom: 5px;">feed</span>
          Editorial
        </div>
      </a>
      <a href="#illustrations" style="text-decoration: none; height: -webkit-fill-available; color: #2c3e50;" class="column-button icon-button-small">
        <div class="column-button">
          <span class="material-icons" style="margin-bottom: 5px;">palette</span>
          Ilustración
        </div>
      </a>
      <a href="#branding" style="text-decoration: none; height: -webkit-fill-available; color: #2c3e50;" class="column-button icon-button-small">
        <div class="column-button">
          <span class="material-icons" style="margin-bottom: 5px;">drive_file_rename_outline</span>
          Branding
        </div>
      </a>
  </nav>
  <nav v-else-if="$route.path =='/social-media'" id="socialmedia-nav" class="team-nav">
    <!-- path changed from /teams to /departamentos -->
      <router-link to="/departamentos" style="text-decoration: none; height: -webkit-fill-available; color: #2c3e50; width: 25%" class="column-button icon-button-small">
        <div class="column-button">
          <span class="material-icons" style="margin-bottom: 5px;">arrow_back</span>
          Atras
        </div>
      </router-link>
      <a href="#targets" style="text-decoration: none; height: -webkit-fill-available; color: #2c3e50; width: 25%" class="column-button icon-button">
        <div class="column-button">
          <span class="material-icons" style="margin-bottom: 5px;">groups</span>
          Audiencia
        </div>
      </a>
      <a href="#portfolio" style="text-decoration: none; height: -webkit-fill-available; color: #2c3e50; width: 25%" class="column-button icon-button">
        <div class="column-button">
          <span class="material-icons" style="margin-bottom: 5px;">grid_view</span>
          Portafolio
        </div>
      </a>
      <a href="#goals" style="text-decoration: none; height: -webkit-fill-available; color: #2c3e50; width: 25%" class="column-button icon-button">
        <div class="column-button">
          <span class="material-icons" style="margin-bottom: 5px;">assignment</span>
          Objetivos
        </div>
      </a>
  </nav>
  <nav v-else-if="$route.path =='/mercadeo'" id="marketing-nav" class="team-nav" style="flex-flow: row;">
    <!-- path changed from /teams to /departamentos -->
      <router-link to="/departamentos" style="text-decoration: none; height: -webkit-fill-available; color: #2c3e50;" class="column-button icon-button-small">
        <div class="column-button">
          <span class="material-icons" style="margin-bottom: 5px;">arrow_back</span>
          Atras
        </div>
      </router-link>
      <a href="#mentorship" style="text-decoration: none; height: -webkit-fill-available; color: #2c3e50;" class="column-button icon-button">
        <div class="column-button">
          <span class="material-icons" style="margin-bottom: 5px;">support_agent</span>
          Asesorías
        </div>
      </a>
      <a href="#trades" style="text-decoration: none; height: -webkit-fill-available; color: #2c3e50;" class="column-button icon-button">
        <div class="column-button">
          <span class="material-icons" style="margin-bottom: 5px;">handshake</span>
          Intercambios
        </div>
      </a>
      <a href="#brand-materials" style="text-decoration: none; height: -webkit-fill-available; color: #2c3e50;" class="column-button icon-button">
        <div class="column-button">
          <span class="material-icons" style="margin-bottom: 5px;">branding_watermark</span>
          Material P.O.P
        </div>
      </a>
      <a href="#litography" style="text-decoration: none; height: -webkit-fill-available; color: #2c3e50;" class="column-button icon-button">
        <div class="column-button">
          <span class="material-icons" style="margin-bottom: 5px;">brush</span>
          Litrografía
        </div>
      </a>
      <a href="#printed-ads" style="text-decoration: none; height: -webkit-fill-available; color: #2c3e50;" class="column-button icon-button">
        <div class="column-button">
          <span class="material-icons" style="margin-bottom: 5px;">travel_explore</span>
          Publicidad Exterior
        </div>
      </a>
      <a href="#events" style="text-decoration: none; height: -webkit-fill-available; color: #2c3e50;" class="column-button icon-button">
        <div class="column-button">
          <span class="material-icons" style="margin-bottom: 5px;">celebration</span>
          Eventos
        </div>
      </a>
      <a href="#promotions" style="text-decoration: none; height: -webkit-fill-available; color: #2c3e50;" class="column-button icon-button">
        <div class="column-button">
          <span class="material-icons" style="margin-bottom: 5px;">badge</span>
          Promotores
        </div>
      </a>
  </nav>
  <nav v-else-if="$route.path =='/studio'" id="studio-nav" class="team-nav">
    <router-link to="/departamentos" style="text-decoration: none; height: -webkit-fill-available; color: #2c3e50; width: 25%" class="column-button icon-button-small">
      <div class="column-button">
        <span class="material-icons" style="margin-bottom: 5px;">arrow_back</span>
        Atras
      </div>
    </router-link>
    <a href="#portfolio" style="text-decoration: none; height: -webkit-fill-available; color: #2c3e50; width: 25%" class="column-button icon-button">
      <div class="column-button">
        <span class="material-icons" style="margin-bottom: 5px;">grid_view</span>
        Portafolio
      </div>
    </a>
    <a href="#photo" style="text-decoration: none; height: -webkit-fill-available; color: #2c3e50; width: 25%" class="column-button icon-button">
      <div class="column-button">
        <span class="material-icons" style="margin-bottom: 5px;">photo_camera</span>
        Fotografía
      </div>
    </a>
    <a href="#audiovisuals" style="text-decoration: none; height: -webkit-fill-available; color: #2c3e50; width: 25%" class="column-button icon-button">
        <div class="column-button">
          <span class="material-icons" style="margin-bottom: 5px;">videocam</span>
          Audiovisual
        </div>
    </a>
  </nav>
  <nav v-else-if="$route.path =='/tecnologias'" id="tech-nav" class="team-nav">
    <!-- path changed from /teams to /departamentos -->
      <router-link to="/departamentos" style="text-decoration: none; height: -webkit-fill-available; color: #2c3e50; width: 25%" class="column-button icon-button-small">
        <div class="column-button">
          <span class="material-icons" style="margin-bottom: 5px;">arrow_back</span>
          Atras
        </div>
      </router-link>
      <a href="#mentorship" style="text-decoration: none; font-size: 14px; height: -webkit-fill-available; color: #2c3e50; width: 25%" class="icon-button">
        <div class="column-button">
          <span class="material-icons" style="margin-bottom: 5px;">
            dashboard
          </span>
          Proyectos
        </div>
      </a>
      <a href="#trades" style="text-decoration: none; font-size: 14px; height: -webkit-fill-available; color: #2c3e50; width: 25%" class="column-button icon-button">
        <span class="material-icons" style="margin-bottom: 5px;">
          web
        </span>
        Desarrollo Web
      </a>
      <a href="#brand-materials" style="text-decoration: none; font-size: 14px; height: -webkit-fill-available; color: #2c3e50; width: 25%" class="column-button icon-button">
        <span class="material-icons" style="margin-bottom: 5px;">
          travel_explore
        </span>
        SEO
      </a>
      <!-- <a href="#litography" style="text-decoration: none; font-size: 12px; height: -webkit-fill-available; color: #2c3e50;" class="column-button icon-button">placeholder</a> -->
  </nav>
  <nav v-else class="team-nav">
      <router-link to="/creativos" style="text-decoration: none; color: #2c3e50;" class="icon-button">
        <div class="column-button">
          <span class="material-icons" style="margin-bottom: 5px;">
            palette
          </span>
          Creativos
        </div>
      </router-link>
      <router-link to="/social-media" style="text-decoration: none; color: #2c3e50;" class="icon-button">
        <div class="column-button">
          <span class="material-icons" style="margin-bottom: 5px;">
            question_answer
          </span>
          Social Media
        </div>
      </router-link>
      <router-link to="/mercadeo" style="text-decoration: none; color: #2c3e50;" class="icon-button">
        <div class="column-button">
          <span class="material-icons" style="margin-bottom: 5px;">
            sell
          </span>
          Mercadeo
        </div>
      </router-link>
      <router-link to="/studio" style="text-decoration: none; color: #2c3e50;" class="icon-button">
        <div class="column-button">
          <span class="material-icons" style="margin-bottom: 5px;">
            camera
          </span>
          Bestway Studio
        </div>
      </router-link>
      <router-link to="/tecnologias" style="text-decoration: none; color: #2c3e50;" class="icon-button">
        <div class="column-button">
          <span class="material-icons" style="margin-bottom: 5px;">
            code
          </span>
          Tecnologías
        </div>
      </router-link>
  </nav>
</template>

<script>
export default {
  name: 'TeamsNav'
}
</script>

<style>
  .team-nav {
    background-color: white;
  }
  .material-icons {
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
  }
  .icon-button {
    width: 20%;
    height: 100%;
    transition: 0.2s;
  }

  .icon-button-small {
    width: 20%;
    height: 100%;
    transition: 0.2s;
  }

  .icon-button:hover {
    background-color: #cecece;
  }

  .icon-button-small:hover {
    background-color: #cecece;
  }

  .column-button {
    font-size: 14px;
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 600px) {
    .icon-button .column-button {
      font-size: 14px;
    }
    #creatives-nav .column-button {
      padding: 0 20px;
    }
    #creatives-nav {
      overflow-x: auto;
      width: 100%;
      white-space: nowrap;
      flex-flow: row;
      justify-content: left;
    }
    #socialmedia-nav {
      overflow-y: scroll;
      overflow-x: hidden;
    }
    #marketing-nav .column-button {
      padding: 0 20px;
    }
    #marketing-nav {
      overflow-x: auto;
      width: 100%;
      white-space: nowrap;
      flex-flow: row;
      justify-content: left;
    }
    #tech-nav {
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }
  @media screen and (orientation: landscape) and (max-height: 420px) {
    #creatives-nav, #socialmedia-nav, #marketing-nav, #tech-nav {
      height: 20%;
    }
   }
</style>
