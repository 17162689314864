<template>
    <div class="servicios-container">
      <div style="display: flex; flex-direction: column; width: 100%">
        <h1 class="section-title">Nuestros Servicios</h1>
        <span>Ofrecemos una gran variedad de servicios para ayudar a tu marca a prosperar.</span>
      </div>
        <div class="item-servicios col-xs-12 col-lg-2"  >
            <div class="card cart-block">
                <router-link to="/creativos#graphic-design" style="text-decoration: none;">
                    <div class="card-img">
                        <svg xmlns="http://www.w3.org/2000/svg" width="95%" height="95%" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                        </svg>
                    </div>
                    <div class="card-block">
                        <h4 class="card-title">DISEÑO GRÁFICO</h4>
                        <p class="card-text">Servicios integrales de diseños, perseguimos una fuerte conexión emocional entre tu marca y tus clientes.</p>
                    </div>
                </router-link>
            </div>
        </div>
        <div class="item-servicios col-xs-12 col-lg-2"  >
            <div class="card cart-block">
                <router-link to="/tecnologias" style="text-decoration: none;">
                    <div class="card-img">
                        <svg xmlns="http://www.w3.org/2000/svg" width="95%" height="95%" fill="currentColor" class="bi bi-laptop" viewBox="0 0 16 16">
                            <path d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z"/>
                        </svg>
                    </div>
                    <div class="card-block">
                        <h4 class="card-title">DISEÑO WEB&nbsp;</h4>
                        <p class="card-text">Diseño web profesional, accesibles, atractivos y de alta calidad de acuerdo a tus necesidades.<br><br></p>
                    </div>
                </router-link>
            </div>
        </div>
        <div class="item-servicios col-xs-12 col-lg-2"  >
            <div class="card cart-block">
                <router-link to="/social-media" style="text-decoration: none;">
                    <div class="card-img">
                        <svg xmlns="http://www.w3.org/2000/svg" width="95%" height="95%" fill="currentColor" class="bi bi-chat-dots" viewBox="0 0 16 16">
                            <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                            <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z"/>
                        </svg>
                    </div>
                    <div class="card-block">
                        <h4 class="card-title">SOCIAL MEDIA</h4>
                        <p class="card-text">Tenga una correcta presencia online, aprovechando plataformas de Social Media como las redes sociales, blogs y otros.</p>
                    </div>
                </router-link>
            </div>
        </div>
        <div class="item-servicios col-xs-12 col-lg-2"  >
            <div class="card cart-block">
                <router-link to="/studio" style="text-decoration: none;">
                    <div class="card-img">
                        <svg xmlns="http://www.w3.org/2000/svg" width="95%" height="95%" fill="currentColor" class="bi bi-camera" viewBox="0 0 16 16">
                            <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"/>
                            <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                        </svg>
                    </div>
                    <div class="card-block">
                        <h4 class="card-title">FOTOGRAFÍA &amp; VIDEO</h4>
                        <p class="card-text">Tus ideas son nuestro objetivo. Exprésate y comunícate efectivamente con el mundo, mediante una imagen de calidad.</p>
                    </div>
                </router-link>
            </div>
        </div>
        <div class="item-servicios col-xs-12 col-lg-2"  >
            <div class="card cart-block">
                <router-link to="/mercadeo#events" style="text-decoration: none;">
                    <div class="card-img">
                        <svg xmlns="http://www.w3.org/2000/svg" width="95%" height="95%" fill="currentColor" class="bi bi-megaphone" viewBox="0 0 16 16">
                            <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-.214c-2.162-1.241-4.49-1.843-6.912-2.083l.405 2.712A1 1 0 0 1 5.51 15.1h-.548a1 1 0 0 1-.916-.599l-1.85-3.49a68.14 68.14 0 0 0-.202-.003A2.014 2.014 0 0 1 0 9V7a2.02 2.02 0 0 1 1.992-2.013 74.663 74.663 0 0 0 2.483-.075c3.043-.154 6.148-.849 8.525-2.199V2.5zm1 0v11a.5.5 0 0 0 1 0v-11a.5.5 0 0 0-1 0zm-1 1.35c-2.344 1.205-5.209 1.842-8 2.033v4.233c.18.01.359.022.537.036 2.568.189 5.093.744 7.463 1.993V3.85zm-9 6.215v-4.13a95.09 95.09 0 0 1-1.992.052A1.02 1.02 0 0 0 1 7v2c0 .55.448 1.002 1.006 1.009A60.49 60.49 0 0 1 4 10.065zm-.657.975 1.609 3.037.01.024h.548l-.002-.014-.443-2.966a68.019 68.019 0 0 0-1.722-.082z"/>
                        </svg>
                    </div>
                    <div class="card-block">
                        <h4 class="card-title">EVENTOS&nbsp;</h4>
                        <p class="card-text">Tu evento se hará conocer en toda tu comunidad, brindamos el mejor servicio profesional de eventos y protocolo.</p>
                    </div>
                </router-link>
            </div>
        </div>
        <div class="item-servicios col-xs-12 col-lg-2"  >
            <div class="card cart-block">
                <router-link to="/mercadeo" style="text-decoration: none;">
                    <div class="card-img">
                        <svg xmlns="http://www.w3.org/2000/svg" width="95%" height="95%" fill="currentColor" class="bi bi-cart-check" viewBox="0 0 16 16">
                            <path d="M11.354 6.354a.5.5 0 0 0-.708-.708L8 8.293 6.854 7.146a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"/>
                            <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                        </svg>
                    </div>
                    <div class="card-block">
                        <h4 class="card-title">MERCADEO</h4>
                        <p class="card-text">Promocionaremos y daremos a conocer tu producto o marca.
                            <br>Evaluaremos tu producto, valoraremos el precio, buscaremos  la plaza y haremos la promoción.
                        </p>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ServicesBlock'
}
</script>

<style scoped>
.section-title {
    margin-top: 5%;
    margin-bottom: 1%;
    width: 100%;
}
span {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 5%;
}
.card-img{
    padding: 1rem;
    margin-top: 4%;
    margin-bottom: 2%;
    background-color: #009a7e;
    transition: 0.3s;
}
.card:hover .card-img {
    color: #009a7e;
    background-color: white;
}

.card:hover h4 {
    color: #009a7e;
}

.card-title {
    color: #2c3e50;
}

.servicios-container{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    height: auto;
    padding: 2% 0;
    box-sizing: border-box;
}

.item-servicios{
    width: 30%;
    height: fit-content;
    min-width: 250px;
    box-sizing: border-box;
}
@media screen and (max-width: 600px) {
    span {
        font-size: 16px;
    }
    .section-title {
        margin-top: 20%;
    }
    .servicios-container {
        padding: 0;
        height: auto;
    }
    #item {
        position: relative;
        height: auto;
    }
    .item-servicios {
        width: 100%;
    }
}
@media screen and (max-width: 900px) {
    .item-servicios {
        padding: 1%;
    }
}
</style>
