<template>
    <div class="budget-container-mobile" id="mobile">
      <div class="budget-user-profile">
        <div class="budget-avatar"></div>
        <div class="budget-profile-data">
          <h3>Agencia de Marketing Integral</h3>
          <p>bestwaymarketing <span style="font-weight: bold">·</span> Instagram</p>
          <p style="color: #282828bf">7,1 mil seguidores <span style="font-weight: bold">·</span> 1 mil publicaciones</p>
          <a href="https://instagram.com/bestwaymarketing/"><button class="budget-ig-btn">Ver perfil</button></a>
        </div>
      </div>
      <!-- Add questions & answers here -->
      <div style="display: flex; flex-direction: column; padding: 0 10px; padding-bottom: 20px;" id="chatMobile">
        <div id="questionOne">
          <div class="response-container" @click="showLikeIcon('like-1', 'container-1')" id="container-1">
            <p style="margin: 10px 15px;">Answer #1: Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            <div class="like-icon" id="like-1">
              <span class="material-icons">favorite</span>
            </div>
          </div>
          <div class="budget-question-container">
            <p style="margin: 10px 15px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            <div class="like-icon">
              <span class="material-icons">favorite</span>
            </div>
          </div>
        </div>
        <div id="questionTwo">
          <div class="response-container" @click="showLikeIcon('like-2', 'container-2')" id="container-2">
            <p style="margin: 10px 15px;">Answer #2: Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            <div class="like-icon" id="like-2">
              <span class="material-icons">favorite</span>
            </div>
          </div>
          <div class="budget-question-container">
            <p style="margin: 10px 15px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            <div class="like-icon">
              <span class="material-icons">favorite</span>
            </div>
          </div>
        </div>
        <div id="questionThree">
          <div class="response-container" @click="showLikeIcon('like-3', 'container-3')" id="container-3">
            <p style="margin: 10px 15px;">Answer #3: Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            <div class="like-icon" id="like-3">
              <span class="material-icons">favorite</span>
            </div>
          </div>
          <div class="budget-question-container">
            <p style="margin: 10px 15px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            <div class="like-icon">
              <span class="material-icons">favorite</span>
            </div>
          </div>
        </div>
        <!-- Not added -->
        <div id="questionFour">
          <div class="response-container">
            <p style="margin: 10px 15px;">Answer #4: Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>
          <div class="budget-question-container">
            <p style="margin: 10px 15px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            <div class="like-icon">
              <span class="material-icons">favorite</span>
            </div>
          </div>
        </div>
      </div>
      <div class="budget-sticky-footer">
        <div class="faqs-mobile">
          <p>Toca para enviar una respuesta sugerida por Bestway Marketing</p>
          <div class="faq-container" @click="showQuestionOne">Hola, ¿cómo puedo obtener información sobre sus servicios?</div>
          <div class="faq-container" @click="showQuestionTwo">¿Pueden enviarme su número de whatsapp?</div>
          <div class="faq-container" @click="showQuestionThree">Hola, me gustaría programar una reunión con su equipo</div>
        </div>
        <div class="budget-mobile-inputs">
          <div class="budget-camera-icon">
            <span class="material-icons">photo_camera</span>
          </div>
          <p>Enviar mensaje...</p>
          <div class="budget-icons-group">
            <span class="material-icons">mic_none</span>
            <span class="material-icons" style="color: #282828; margin: auto 2px">image</span>
            <span class="material-icons">insert_emoticon</span>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'BudgetMobile',
  data () {
    return {
      questionOne: false,
      questionTwo: false,
      questionThree: false,
      questionFour: false,
      isTapped: false
    }
  },
  methods: {
    showQuestionOne () {
      this.questionOne = !this.questionOne
      var chat = document.getElementById('questionOne')
      if (this.questionOne) {
        chat.style.display = 'block'
        chat.scrollIntoView({ behavior: 'smooth' })
        this.questionOne = !this.questionOne
      } else {
        chat.style.display = 'none'
      }
    },
    showQuestionTwo () {
      this.questionTwo = !this.questionTwo
      var chat = document.getElementById('questionTwo')
      if (this.questionTwo) {
        chat.style.display = 'block'
        chat.scrollIntoView({ behavior: 'smooth' })
        this.questionTwo = !this.questionTwo
      } else {
        chat.style.display = 'none'
      }
    },
    showQuestionThree () {
      this.questionThree = !this.questionThree
      var chat = document.getElementById('questionThree')
      if (this.questionThree) {
        chat.style.display = 'block'
        chat.scrollIntoView({ behavior: 'smooth' })
        this.questionThree = !this.questionThree
      } else {
        chat.style.display = 'none'
      }
    },
    showQuestionFour () {
      this.questionFour = !this.questionFour
      var chat = document.getElementById('questionFour')
      if (this.questionFour) {
        chat.style.display = 'block'
        chat.scrollIntoView({ behavior: 'smooth' })
        this.questionFour = !this.questionFour
      } else {
        chat.style.display = 'none'
      }
    },
    showLikeIcon (id, containerID) {
      var icon = document.getElementById(id)
      const container = document.getElementById(containerID)
      this.isTapped = !this.isTapped
      console.log(this.isTapped)
      if (this.isTapped) {
        container.style.marginBottom = '40px'
        icon.style.display = 'block'
        this.isTapped = !this.isTapped
        console.log(this.isTapped)
      } else {
        container.style.marginBottom = '20px'
        icon.style.display = 'none'
        this.isTapped = !this.isTapped
      }
    }
  }
}
</script>

<style>
  .budget-container-mobile {
    display: none;
    height: -webkit-fill-available;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    background-color: #f5f5f5;
  }
  .budget-user-profile {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
  }
  .budget-avatar {
    margin: auto;
    margin-top: 20px;
    min-height: 120px;
    width: 120px;
    border-radius: 50%;
    background-color: #e3e3e3;
    background-image: url(../../assets/Logo-BestwayMarketing.svg);
    background-repeat: no-repeat;
    background-size: 90px;
    background-position: center;
  }
  .budget-profile-data {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #282828;
  }
  .budget-profile-data h3, .budget-profile-data p {
    margin: 0;
  }
  .budget-profile-data h3 {
    margin: 2px 0;
    margin-top: 20px;
  }
  .budget-profile-data p {
    margin: 2px 0;
  }
  .budget-ig-btn {
    margin: 0 auto;
    margin-top: 20px;
    width: 85px;
    padding: 10px;
    color: #282828;
    font-weight: bold;
    border-radius: 10px;
    border-style: none;
    outline: none;
    background-color: #e3e3e3;
  }
  .budget-sticky-footer {
    position: sticky;
    bottom: 0px;
    padding-bottom: 20px;
    border-top: solid 1px rgba(40, 40, 40, 0.1);
    background-color: #f5f5f5;
  }
  .budget-mobile-inputs {
    margin: 0 20px;
    margin-top: 10px;
    width: -webkit-fill-available;
    height: 50px;
    border-radius: 40px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(40, 40, 40, 0.15);
    display: flex;
  }
  .budget-camera-icon {
    border-radius: 50%;
    margin: auto 0;
    margin-left: 5px;
    height: 40px;
    width: 40px;
    background-image: linear-gradient(240deg, #52C1FF, #007EC6);
    display: flex;
  }
  .budget-container-mobile .material-icons {
    color: #f5f5f5;
    margin: auto;
  }
  .budget-mobile-inputs p {
    margin: auto;
    margin-left: 10px;
    color: #282828bf;
  }
  .budget-icons-group {
    margin-right: 10px;
    display: flex;
    flex-direction: row;
  }
  .budget-icons-group .material-icons {
    font-size: 30px;
    color: #282828;
    margin: auto 2px;
  }
  .faqs-mobile {
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    text-align: start;
    color: #007EC6;
    font-size: 14px;
  }
  .faqs-mobile p {
    text-align: center;
    font-size: 12px;
    color: #282828bf;
    margin-bottom: 5px;
  }
  .faq-container {
    margin: 5px 0;
    padding: 10px 12px;
    border-radius: 20px;
    background-color: #e3e3e3;
  }
  .response-container {
    display: flex;
    flex-direction: column;
    height: 80px;
    width: 250px;
    color: #282828;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(40, 40, 40, 0.15);
    margin: 20px;
    border-radius: 20px;
    text-align: start;
  }
  .budget-question-container {
    display: flex;
    flex-direction: column;
    height: 60px;
    width: 250px;
    color: #282828;
    background-color: #e3e3e3;
    margin: 20px;
    margin-left: auto;
    margin-right: 20px;
    margin-bottom: 30px;
    border-radius: 20px;
    text-align: start;
  }
  .like-icon {
    position: relative;
    right: -8px;
    bottom: 2px;
    padding: 3px 8px;
    background: #e3e3e3;
    border-color: #f5f5f5;
    border-width: 2px;
    border-radius: 20px;
    border-style: solid;
    width: 20px;
    height: 20px;
  }
  .response-container .like-icon {
    display: none;
    background-color: #f5f5f5;
    border-color: rgba(40, 40, 40, 0.15);
    border-width: 1px;
  }
  .like-icon .material-icons {
    color: rgb(204, 32, 32);
    font-size: 20px;
  }
  #questionOne, #questionTwo, #questionThree, #questionFour {
    display: none;
  }
</style>
