<template>
    <div class="budget-container-desktop">
      <!-- Windows navigation -->
      <div class="windows-nav">
        <div class="back-services">
          <router-link to="/">
            <span class="material-icons" style="color: #282828">arrow_back</span>
          </router-link>
          <p style="font-size: 20px; color: #282828; margin-left: 10px;">Presupuestos</p>
        </div>
        <div class="windows-buttons">
          <span class="material-icons" style="cursor: default;">minimize</span>
          <div style="width: 10px"></div>
          <span class="material-icons" style="padding-top: 5px; cursor: default;">close</span>
        </div>
      </div>
      <div class="row-container">
        <!-- Left side container -->
        <div class="budget-container-left">
          <div class="budget-header-left">
            <h1>Presupuestos</h1>
            <span class="material-icons" id="budget-help">help</span>
          </div>
          <div class="budget-subheader">
            <span class="material-icons" id="budget-history">history</span>
            <h3>Preguntas Frecuentes</h3>
            <span class="material-icons" id="budget-arrow">expand_more</span>
          </div>
          <!-- Questions containers -->
          <div class="faqs">
            <div class="question-container" @click="showChat('chatOne')">
              <div class="icon-circle">
                <span class="material-icons" style="margin: auto;">info</span>
              </div>
              <p>Me interesa recibir asesorias de Marketing Digital...</p>
            </div>
            <div class="question-container" @click="showChat('chatTwo')">
              <div class="icon-circle">
                <span class="material-icons" style="margin: auto;">store</span>
              </div>
              <p>Me interesa conocer los servicios para mi marca...</p>
            </div>
            <div class="question-container" @click="showChat('chatThree')">
              <div class="icon-circle">
                <span class="material-icons" style="margin: auto;">developer_mode</span>
              </div>
              <p>Me interesan los servicios de social media...</p>
            </div>
            <div class="question-container" @click="showChat('chatFour')">
              <div class="icon-circle">
                <span class="material-icons" style="margin: auto;">devices</span>
              </div>
              <p>Me interesan los servicios de tecnologia...</p>
            </div>
          </div>
        </div>
        <div class="budget-container-right">
          <!-- Chat header / Profile -->
          <div class="budget-header-right">
            <div class="bwm-avatar"></div>
            <div class="column-flex-budget">
              <h3>Bestway Marketing</h3>
              <div class="row-flex" style="justify-content: flex-start">
                <div class="budget-status-profile"></div>
                <p>Disponible</p>
              </div>
            </div>
            <span class="material-icons" id="budget-more" style="font-weight: bold">more_vert</span>
          </div>
          <hr/>
          <!-- Changing content -->
          <div class="chatbox">
            <div class="today-btn"><p>Hoy</p></div>
            <div class="chat-flex">
              <div id="chatOne">
                <ChatOne/>
              </div>
              <div id="chatTwo">
                <ChatTwo/>
              </div>
              <div id="chatThree">
                <ChatThree/>
              </div>
              <div id="chatFour">
                <ChatFour/>
              </div>
            </div>
          </div>
          <!-- Chat box -->
          <div class="chatbox-inputs">
            <div class="inputs">
              <p>Enviar mensaje...</p>
              <span class="material-icons" id="budget-add">add_circle</span>
            </div>
            <div class="send-btn">
              <span class="material-icons" id="budget-send">send</span>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import ChatOne from '@/components/presupuestos/Budget_chat_one.vue'
import ChatTwo from '@/components/presupuestos/Budget_chat_two.vue'
import ChatThree from '@/components/presupuestos/Budget_chat_three.vue'
import ChatFour from '@/components/presupuestos/Budget_chat_four.vue'
export default {
  name: 'BudgetDesktop',
  components: {
    ChatOne,
    ChatTwo,
    ChatThree,
    ChatFour
  },
  data () {
    return {
      isSelected: false
    }
  },
  methods: {
    showChat (id) {
      var chat = document.getElementById(id)
      this.isSelected = !this.isSelected
      if (this.isSelected) {
        chat.style.display = 'block'
        chat.scrollIntoView({ behavior: 'smooth', block: 'end' })
        this.isSelected = !this.isSelected
      } else {
        chat.style.display = 'none'
      }
    }
  }
}
</script>

<style>
  .chat-flex {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
  .budget-container-desktop ::-webkit-scrollbar {
    width: 5px;
  }
  .budget-container-desktop ::-webkit-scrollbar-track {
    background: rgba(245, 245, 245, 0.1);
    border-radius: 20px;
  }
  .budget-container-desktop ::-webkit-scrollbar-thumb {
    background: rgba(40, 40, 40, 0.5);
    border-radius: 20px;
  }
  .budget-container-desktop ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(40, 40, 40, 0.8);
  }
  /*
  .animated-background {
    background-image: linear-gradient(130deg, #3407EA, #EF4B4B);
    background-size: 100%;
  }
  */
  .windows-nav {
    height: 50px;
    /*width: 100%;*/
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
  }
  .back-services {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .windows-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .budget-container-desktop {
    height: 100%;
    width: 100%;
    display: flex;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 20px;
    background-image: linear-gradient(135deg, rgba(250, 250, 250, 0.4), rgba(250, 250, 250, 0.15));
    flex-direction: column;
  }
  .row-container {
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow-y: auto;
  }
  .budget-container-desktop .material-icons {
    color: #f5f5f5;
    font-size: 30px;
    transition: 0.2s linear;
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
  }
  .budget-container-left {
    height: 65vh;
    width: 440px;
    margin-top: 20px;
    margin-right: 20px;
    border-radius: 40px;
    /* background-color: rgba(245, 245, 245, 0.5); */
  }
  .budget-container-right {
    display: flex;
    flex-direction: column;
    height: 65vh;
    width: 600px;
    margin-top: 20px;
    margin-right: 20px;
    border-radius: 40px;
    background-color: rgba(245, 245, 245, 0.25);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0 2px 14px 6px rgb(0 0 0 / 5%);
  }
  .budget-header-left {
    height: 100px;
    width: 100%;
    display: flex;
    /* background-color: #f5f5f5; */
  }
  .budget-header-left h1 {
    margin: auto 0;
    margin-left: 35px;
    color: #282828
  }
  #budget-help {
    margin: auto;
    margin-left: 20px;
    color: #282828;
  }
  .budget-subheader {
    height: 50px;
    background-color: rgb(245, 245, 245, 0.5);
    width: 85%;
    margin: 0 auto;
    border-radius: 35px;
    display: flex;
    justify-content: center;
  }
  .budget-subheader h3 {
    color: #282828;
    margin: auto;
  }
  #budget-history {
    color: #282828;
    margin: auto;
  }
  #budget-arrow {
    color: #282828;
    margin: auto;
  }
  .faqs {
    padding: 10px;
    overflow-y: auto;
  }
  .question-container {
    margin: 0 auto;
    height: 60px;
    width: 90%;
    background-color: #f5f5f5;
    border-radius: 40px;
    margin-top: 10px;
    display: flex;
    justify-content: left;
    font-size: 12px;
    text-align: start;
    cursor: pointer;
    transition: 0.2s linear;
  }
  .question-container:hover {
    background-color: #e3e3e3;
  }
  .question-container p {
    margin: auto;
    margin-left: 0px;
  }
  .icon-circle {
    display: flex;
    margin: auto 15px;
    background-color: #282828;
    border-radius: 50%;
    height: 40px;
    min-width: 40px;
  }
  .budget-header-right {
    margin: 20px;
    display: flex;
    flex-direction: row;
  }
  .bwm-avatar {
    border-radius: 50%;
    background-color: #282828;
    background-image: url(../../assets/logo-bw-white.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 60px;
    width: 60px;
  }
  .column-flex-budget {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;
  }
  .column-flex-budget h3, .column-flex-budget p {
    font-weight: 500;
    color: #282828;
    margin: 0;
  }
  .row-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .budget-status-profile {
    border-radius: 50%;
    height: 15px;
    width: 15px;
    margin-right: 10px;
    background-color: #76ee76;
  }
  #budget-more {
    color: #282828;
    margin: auto;
    margin-right: 0px;
    cursor: pointer;
  }
  hr {
    border-style: none;
    margin: 0 20px;
    margin-bottom: 20px;
    height: 1px;
    background-color: #28282840;
  }
  .today-btn {
    min-height: 30px;
    width: 55px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  .today-btn p {
    margin: auto;
    font-size: 14px;
  }
  .chatbox-inputs {
    margin: 0 20px;
    margin-top: auto;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
  }
  .inputs {
    margin-right: 15px;
    display: flex;
    flex-direction: row;
    height: 42px;
    width: -webkit-fill-available;
    border-radius: 40px;
    border-style: solid;
    border-color: #f5f5f5;
    border-width: 2px;
  }
  .inputs p {
    margin: auto;
    margin-left: 20px;
    font-weight: 600;
    color: #282828cc;
  }
  #budget-add {
    margin: auto;
    margin-right: 20px;
    color: #f5f5f5;
    cursor: pointer;
  }
  .send-btn {
    display: flex;
    margin: auto;
    margin-right: 0;
    height: 45px;
    min-width: 45px;
    border-radius: 50%;
    background-color: rgba(40, 40, 40, 0.5);
    border-style: none;
    border-width: 2px;
    border-color: rgba(40, 40, 40, 0.5);
    cursor: pointer;
  }
  #budget-send {
    margin: auto;
    cursor: pointer;
    font-size: 24px;
  }
  .chatbox {
    margin: 0 20px;
    padding-right: 10px;
    margin-bottom: 15px;
    overflow-y: auto;
  }
  .chat-msg-container {
    margin: 15px 0;
    margin-bottom: 5px;
    padding: 15px;
    padding-left: 30px;
    width: 75%;
    color: #282828;
    text-align: left;
    background-color: rgba(245, 245, 245, 0.75);
    border-radius: 2px 40px 40px 40px;
  }
  .chat-msg-altern {
    margin-top: 15px;
    margin-bottom: 5px;
    padding: 15px;
    padding-left: 30px;
    margin-left: auto;
    width: 75%;
    color: #f5f5f5;
    text-align: left;
    background-color: rgba(40, 40, 40, 0.5);
    border-radius: 40px 2px 40px 40px;
  }
  .timer-msg {
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 10px;
    margin-bottom: 15px;
  }
  .timer-msg p {
    color: #282828cc;
    font-weight: bold;
  }
  #chat-history {
    font-size: 15px;
    margin-right: 5px;
    margin-left: 30px;
    color: #282828cc;
  }
  #chatOne {
    display: none;
  }
  #chatTwo {
    display: none;
  }
  #chatThree {
    display: none;
  }
  #chatFour {
    display: none;
  }
</style>
