<template>
  <div class="contact-flex">
    <!-- Card Large -->
    <div class="custom-card" id="large">
      <div class="header-container"></div>
      <div class="avatar-circle"></div>
      <div class="content-media">
        <h2>Bestway Marketing</h2>
        <p style="font-size: 14px; margin-bottom: 8%">Somos amantes del arte, la innovación y la música, de eso nos inspiramos para hacer del marketing digital, las redes sociales y el diseño web, una mejor experiencia.</p>
      </div>
      <div class="btn-padding">
        <a href="https://www.instagram.com/bestwaymarketing/" target="_blank" style="text-decoration: none;">
          <button class="stadium-button" id="instagram-button">
            <ion-icon name="logo-instagram" class="icon-class" style="margin-right: 5px;"></ion-icon>
            Síguenos en Instagram
          </button>
        </a>
      </div>
      <div class="btn-padding">
        <a href="https://www.twitter.com/@bwaymarketing/" target="_blank" style="text-decoration: none;">
          <button class="stadium-button" id="twitter-button">
            <ion-icon name="logo-twitter" class="icon-class" style="margin-right: 5px;"></ion-icon>
            Síguenos en Twitter
          </button>
        </a>
      </div>
    </div>

    <div class="contact-flex-two">
      <!-- Card Small -->
      <div class="custom-card" id="small">
        <div class="contact-flex-two contact-card-inner">
          <div class="list-tile">
            <div class="contact-avatar"></div>
            <div class="tile-column">
              <h4>Bestway Marketing</h4>
              <div class="status-row">
                <div class="status-circle"></div>
                <p>Disponible</p>
              </div>
            </div>
            <a href="https://wa.me/584165445985" target="_blank" style="text-decoration: none;">
              <button class="stadium-button stadium-button-altern">
                <ion-icon name="logo-whatsapp" style="margin-right: 5px;"></ion-icon>
                <p class="button-text-stadium">Contactar</p>
              </button>
            </a>
          </div>
          <div class="divider"></div>
          <div class="list-tile">
            <div class="contact-avatar contact-avatar-studio" style="background-color: #f5f5f5;"></div>
            <div class="tile-column">
              <h4>Bestway Studio</h4>
              <div class="status-row">
                <div class="status-circle"></div>
                <p>Disponible</p>
              </div>
            </div>
            <a href="https://wa.me/584165445985" target="_blank" style="text-decoration: none;">
              <button class="stadium-button stadium-button-altern">
                <ion-icon name="logo-whatsapp" style="margin-right: 5px;"></ion-icon>
                <p class="button-text-stadium">Contactar</p>
              </button>
            </a>
          </div>
          <div class="divider"></div>
          <div class="list-tile">
            <div class="contact-avatar"></div>
            <div class="tile-column">
              <h4>Bestway Developers</h4>
              <div class="status-row">
                <div class="status-circle"></div>
                <p>Disponible</p>
              </div>
            </div>
            <a href="https://wa.me/584165445985" target="_blank" style="text-decoration: none;">
              <button class="stadium-button stadium-button-altern">
                <!-- <ion-icon name="logo-icon" class="icon-media"></ion-icon> -->
                <ion-icon name="logo-whatsapp" style="margin-right: 5px;"></ion-icon>
                <p class="button-text-stadium">Contactar</p>
              </button>
            </a>
          </div>
        </div>
      </div>

      <!-- Card Medium -->
      <div class="custom-card" id="medium">
        <div class="contact-flex-two .contact-card-inner-forms" style="padding: 15px;">
          <img src="../assets/design_services.svg" alt="design-services" height="60" width="60" style="margin: 0 auto;">
          <h3 style="margin: 0">¡Hacemos de tus sueños una realidad!</h3>
          <p>Empecemos a trabajar juntos con uno de los siguientes formularios de contacto.</p>
          <div class="btn-padding">
            <button class="stadium-button">
              <ion-icon name="information-circle-outline" class="icon-media" style="margin-right: 5px"></ion-icon>
              Formulario Guía de Marcas
            </button>
          </div>
          <div class="btn-padding">
            <button class="stadium-button stadium-button-altern" id="studio-reserve" style="width: 100%; font-size: 18px">
              <ion-icon name="camera-outline" class="icon-media" style="margin-right: 5px"></ion-icon>
              Reservas Bestway Studio
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contactos'
}
</script>

<style scoped>
  .contact-flex {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .divider {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, .1);
  }
  .list-tile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .list-tile p, h4 {
    margin: 0;
  }
  .tile-column {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-right: auto;
    margin-left: 10px;
  }
  .status-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
  }
  .status-circle {
    background-color: #1cc446;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .icon-media {
    margin-right: 5px;
    height: 20px;
    width: 20px;
  }
  .contact-flex-two {
    display: flex;
    flex-direction: column;
  }
  .contact-card-inner {
    padding: 20px;
    height: 260px;
    justify-content: space-between;
  }
  .contact-card-inner-forms {
    padding: 20px;
    height: 260px;
    justify-content: space-between;
  }
  .custom-card {
    background-image: linear-gradient(130deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.2));
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    color: #282828;
  }
  #large {
    height: 620px;
    width: 400px;
    margin-right: 20px;
  }
  h2 {
    margin-top: 30px;
    margin-bottom: 10px;
  }
  #large p {
    margin-bottom: 50px;
  }
  #small {
    height: 300px;
    width: 430px;
    margin-left: 20px;
  }
  #medium {
    height: 300px;
    width: 430px;
    margin-top: 20px;
    margin-left: 20px;
  }
  #medium p {
    font-size: 14px;
    margin: 20px 0;
  }
  .header-container {
    height: 250px;
    width: 100%;
    /*background-color: #f5f5f5;*/
    background-image: linear-gradient(rgba(250, 250, 250, 0.2), rgba(250, 250, 250, 0.5)), url(../assets/img/inicio-socials-instagram_bwm.jpeg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .avatar-circle {
    margin: auto;
    margin-top: -150px;
    z-index: 1;
    height: 240px;
    width: 240px;
    border-radius: 50%;
    background-color: #282828;
    background-image: url(../assets/logo-bw-white.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .contact-avatar {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: #282828;
    background-image: url(../assets/logo-bw-white.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .contact-avatar-studio {
    background-image: url(../assets/Logo-BestwayMarketing.svg);
  }
  .content-media {
    padding: 0 10px;
  }
  .btn-padding {
    margin-top: auto;
    margin-bottom: 15px;
    padding: 0 20px;
  }
  .stadium-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
    position: relative;
    width: 100%;
    margin-top: auto;
    padding: 10px;
    overflow: hidden;
    border-width: 2px;
    outline: none;
    border: none;
    border-radius: 40px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .2);
    color: white;
    background-color: #009a7e;
    transition: background-color .3s;
  }
  .stadium-button:hover {
    color: #009a7e;
    background-color: white;
  }
  .stadium-button:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 0;
    padding-top: 0;
    border-radius: 100%;
    background-color: rgba(236, 240, 241, .3);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .stadium-button:active::before {
    width: 120%;
    padding-top: 120%;
    transition: width .2s ease-out, padding-top .2s ease-out;
  }
  .stadium-button img {
    margin-right: 10px;
  }
  .stadium-button-altern {
    margin: 0;
    height: 45px;
    width: 130px;
    font-size: 16px;
    color: #dcdcdc;
    background-color: #282828;
  }
  .stadium-button-altern:hover {
    background-color: #f5f5f5;
    color: #009a7e;
  }
  #studio-reserve {
    background-color: white;
    color: #009a7e;
    font-size: 18px;
  }
  #studio-reserve:hover {
    background-color: #009a7e;
    color: white;
  }
  #instagram-button {
    background-color: #7000d9;
    color: white;
  }
  #instagram-button:hover {
    background-color: white;
    color: #7000d9;
  }
  #twitter-button {
    background-color: white;
    color: #7000d9;
  }
  #twitter-button:hover {
    background-color: #7000d9;
    color: white;
  }
  @media screen and (max-width: 600px) {
    .container {
      padding: 20px;
    }
    .contact-flex {
      margin-top: 0;
      padding: 20px;
      flex-direction: column;
    }
    .container-flex-center {
      flex-direction: column;
      flex-wrap: nowrap;
    }
    .header-container {
      height: 130px;
    }
    #large {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 420px;
      margin-right: 0;
    }
    #large h4 {
      margin-bottom: 10px;
    }
    #large p {
      margin-bottom: 20px;
    }
    #large .avatar-circle {
      margin-top: -75px;
      height: 120px;
      width: 120px;
    }
    #large .stadium-button {
      font-size: 16px;
    }
    #small {
      margin-top: 20px;
      margin-left: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 260px;
    }
    #medium {
      margin-top: 20px;
      margin-left: 0;
      display: flex;
      width: 100%;
      height: 325px;
    }
    #medium .stadium-button {
      font-size: 14px;
    }
    #medium .btn-padding {
      padding: 0;
    }
    .contact-avatar {
      height: 45px;
      width: 45px;
    }
    .contact-card-inner {
      height: inherit;
    }
    .contact-card-inner-forms {
      height: auto;
    }
    .list-tile p {
      font-size: 14px;
    }
    .status-row p {
      font-size: 12px;
    }
    .stadium-button-altern {
      height: 45px;
      width: 45px;
    }
    .icon-media {
      margin: 0;
    }
    .button-text-stadium {
      display: none;
    }
    #medium p {
      font-size: 12px;
      margin: 15px 0;
    }
  }
</style>
