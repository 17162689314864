<template>
  <div class="quote-container">
    <div class="quote-msg">
        <h3 class="quote-title">
            El mejor marketing no parece marketing.
        </h3>
        <p class="quote-subtitle">- Tom Fishburne.</p>
    </div>
    <div class="quote-img">
        <i >
            <svg class="quote-icon"
                viewBox="0 0 24 24" height="95%" width="95%" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.9 3.01a.51.49 0 0 0-.258.06l-3.744 1.954-3.807-1.84a.51.49 0 0 0-.733.526l.787 4.008-3.007 2.893a.51.49 0 0 0 .296.83l4.23.523 1.95 3.628a.51.49 0 0 0 .917-.012l1.828-3.684 4.21-.65a.51.49 0 0 0 .272-.84l-3.1-2.8.654-4.03a.51.49 0 0 0-.493-.563zm-.675 1.394l-.54 3.305a.51.49 0 0 0 .155.428l2.544 2.297-3.454.534a.51.49 0 0 0-.38.27l-1.5 3.025-1.597-2.977a.51.49 0 0 0-.39-.262l-3.47-.428 2.467-2.372a.51.49 0 0 0 .14-.435l-.646-3.285 3.122 1.51a.51.49 0 0 0 .477-.008l3.072-1.603zM24 7.5c0 .277-.223.5-.5.5h-2c-.277 0-.5-.223-.5-.5s.223-.5.5-.5h2c.277 0 .5.223.5.5zM4.482 4.996c-.482 0-.69.72-.205.95l2 1c.55.303 1.053-.622.446-.893l-2-1c-.074-.04-.157-.058-.24-.057zM17.5 14c-.387-.003-.615.386-.442.723l1 2c.288.643 1.15.018.895-.446l-1-2c-.082-.17-.265-.276-.453-.277zm-4-14c.277 0 .5.223.5.5v2c0 .277-.223.5-.5.5s-.5-.223-.5-.5v-2c0-.277.223-.5.5-.5zM9.49 13c-.13 0-.253.054-.344.147l-8.72 8.72c-.273.27-.426.603-.426.94 0 .327.103.642.32.863.217.222.532.33.862.33.334 0 .675-.152.95-.427l8.722-8.72c.453-.434-.264-1.15-.708-.706l-8.72 8.72c-.33.328-.595.006-.294-.294l8.722-8.72c.3-.294.1-.856-.364-.853z"/>
            </svg>
        </i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuoteBlock'
}
</script>

<style>
.quote-container{
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    height: 40%;
    padding: 1.2rem;
    box-sizing: border-box;
    background-color: #0a9f7ddd;
    color: #FFF
}

.quote-msg{
    display: flex;
    flex-flow: row wrap;
    width: 55%;
    text-align: center;
    text-shadow: 1px 1px 3px #3336;
    margin: 0;
    font-size: 4vh;
    justify-content: center;
    align-items: center;
}

.quote-img{
    width: 40%;
    height: 100%;
    max-height: 30vh;
}
.quote-icon{
    max-width: 100%;
    filter: drop-shadow(2px 6px 6px #333c);
}

.quote-title{
    font-weight: bold;
    margin: 0;
}

.quote-subtitle{
    font-size: 0.5em;
    font-weight: 300;
    opacity: 0.7;
}
@media screen and (max-width: 600px) {
    .quote-title {
        font-size: x-large;
    }
}
@media screen and (max-width: 900px) {
    .quote-title {
        font-size: 1.6rem;
    }
}
</style>
