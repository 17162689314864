<template>
  <BudgetDesktop/>
  <BudgetMobile/>
</template>

<script>
import BudgetMobile from '@/components/presupuestos/Budget_mobile.vue'
import BudgetDesktop from '@/components/presupuestos/Budget_desktop.vue'
export default {
  name: 'Budget',
  components: {
    BudgetDesktop,
    BudgetMobile
  }
}
</script>

<style>
  @media screen and (max-width: 800px) {
    /*
    .animated-background {
      background: none;
      background-color: #f5f5f5;
    }
    */
    .main-container .container {
      border-radius: 0;
    }
    .budget-container-desktop {
      display: none;
    }
    .budget-container-mobile {
      display: flex;
    }
  }
</style>
