<template>
    <section class="clients-title">
        <div class="img-block">
            <i class="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="95%" height="95%" fill="currentColor" class="bi bi-hand-thumbs-up" viewBox="0 0 16 16">
                    <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"/>
                </svg>
            </i>

        </div>
        <div class="title-block">
            <h3 class="title">
                Algunas de las marcas que confían en nosotros...
            </h3>
        </div>
    </section>
    <section class="clients-scroll">
        <div id="splide">
            <div class="splide">
                <div class="splide__track">
                        <ul class="splide__list">
                            <li class="splide__slide"><img src="../../assets/brands/harpers.png" class="img-medium" alt="Sample 1"></li>
                            <li class="splide__slide"><img src="../../assets/brands/pipo-logo.png" class="img-medium" alt="Sample 1"></li>
                            <li class="splide__slide"><img src="../../assets/brands/san-cipriano.png" class="img-medium" alt="Sample 1"></li>
                            <li class="splide__slide"><img src="../../assets/brands/lacteos-yaracuy.png" class="img-small" alt="Sample 2"></li>
                            <li class="splide__slide"><img src="../../assets/brands/logo-sandra.png" class="img-medium" alt="Sample 1"></li>
                            <li class="splide__slide"><img src="../../assets/brands/logo-salon-301.png" class="img-medium" alt="Sample 1"></li>
                            <li class="splide__slide"><img src="../../assets/brands/logo-cmm.png" class="img-large" alt="Sample 3"></li>
                            <li class="splide__slide"><img src="../../assets/brands/hesperia.png" class="img-large" alt="Sample 3"></li>
                            <li class="splide__slide"><img src="../../assets/brands/euromax.png" class="img-large" alt="Sample 3"></li>
                            <li class="splide__slide"><img src="../../assets/brands/luxor.png" class="img-large" alt="Sample 3"></li>
                            <li class="splide__slide"><img src="../../assets/brands/maria-rodriguez.png" class="img-medium" alt="Sample 1"></li>
                            <li class="splide__slide"><img src="../../assets/brands/vivir-en-mcy-logo.png" class="img-small" alt="Sample 2"></li>
                            <li class="splide__slide"><img src="../../assets/brands/toyota-retro.png" class="img-small" alt="Sample 2"></li>
                            <li class="splide__slide"><img src="../../assets/brands/smery-shop-logo.png" class="img-medium" alt="Sample 1"></li>
                            <li class="splide__slide"><img src="../../assets/brands/puro-capital-1.png" class="img-medium" alt="Sample 1"></li>
                            <li class="splide__slide"><img src="../../assets/brands/logo-capsula.png" class="img-medium" alt="Sample 1"></li>
                            <li class="splide__slide"><img src="../../assets/brands/santa-lucia.png" class="img-medium" alt="Sample 1"></li>
                        </ul>
                </div>
            </div>
        </div>
    </section>
    <section class="clients-ws">
        <div class="img-block">
            <i class="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="95%" height="95%" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                    <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
                </svg>
            </i>
        </div>
        <div class="title-block">
            <h3 class="title">
                Con un solo CLICK podrás contactarnos
            </h3>
            <button class="btn">
                <!--
                <i class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="95%" height="95%" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                        <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
                    </svg>
                </i>
                -->
                Justo Aqui
            </button>
        </div>
    </section>
</template>

<script>
import Splide from '@splidejs/splide'
import '@splidejs/splide/dist/css/splide.min.css'
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll'
import { Grid } from '@splidejs/splide-extension-grid'
export default {
  name: 'ClientsBlock',
  mounted () {
    const splide = new Splide('.splide', {
      type: 'loop',
      drag: 'free',
      focus: 'center',
      rewind: 'true',
      autoWidth: 'true',
      perPage: 6,
      autoScroll: {
        speed: 3
      },
      grid: {
        rows: 2,
        cols: 2,
        gap: {
          row: '1rem',
          col: '1.5rem'
        }
      }
    })
    splide.mount({ AutoScroll, Grid })
  }
}
</script>

<style>

.clients-title{
    height: 25%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    background: linear-gradient(180deg, #dddd, transparent);
    padding: 3%;
    margin-top: 5%;
    color: #70009D;
}
.clients-title .img-block{
    width: 30%;
    height: 100%;
}

.clients-title .title-block{
    width: 60%;
    height: 100%;
    font-size: 2rem;
}

.clients-scroll{
    margin-bottom: 40px;
    height: auto;
    overflow-x:auto ;
    overflow-y:hidden;
}

.clients-ws{
    height: 60%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    box-sizing: border-box;
    background: linear-gradient(180deg, #0a9f7d99, #0a9f7dee);
    color: #FFF
}

.clients-ws .img-block{
    width: 40%;
    padding: 5%;
    box-sizing: border-box;
    max-height: 100%;
}

.clients-ws .title-block{
    width: 58%;
    text-align: center;
    font-size: 2.5rem;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
}
.title-block .btn{
    height: 2rem;
}

.title-block .btn {
  padding: 0.5rem;
  width: 30%;
  height: 3em;
  border-radius: 2rem;
  color: #0a9f7dee;
  background-color: #FFF;
  font-weight: bold;
  font-size: 1.2rem;
  border: none;
  box-shadow: 0px 0px 1px 4px #eee9;
  transition: all ease-in-out 250ms;
}

.title-block .btn:hover {
  color: #FFF;
  background-color: #0a9f7d;
}

.clients-ws .title-block .title{
    margin: 0;
}

i.icon{
    height: 10vw;
    width: 10vw;
    box-sizing: border-box;
}

.img-large {
    height: 350px;
    width: 350px;
    object-fit: contain;
}

.img-medium {
    height: 250px;
    width: 250px;
    object-fit: contain;
}

.img-small {
    height: 150px;
    width: 150px;
    object-fit: contain;
}

@media screen and (max-width: 600px) {
    .clients-title {
        margin-top: 20%;
    }
    .title-block h3 {
        font-size: x-large;
    }
    .clients-ws {
        height: 35%;
    }
    .title-block .btn {
        width: 65%;
    }
    .clients-scroll {
        margin-bottom: 0;
        height: 90%;
    }
}
@media screen and (max-width: 900px) {
    .clients-scroll {
        margin-bottom: 0;
    }
}
</style>
