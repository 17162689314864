<template>
  <div v-if="this.$root.bgvideo === false" class="hello" >
    <h1 >
      <span 
          style="
            opacity: 0;
            position: absolute;
            top: 0px;
            left: 50%;
            text-align: center;
            transform: translateX(-50%);
          "
      >Bestway Marketing.</span>
      <p style="font-size:0.7em; margin: 0;">Creemos. Creamos.</p> Crecemos Contigo... <br>
      <i @click="toggleVideo" >
        <svg xmlns="http://www.w3.org/2000/svg" width="0.9em" height="0.9em" fill="currentColor" class="bi bi-play-circle-fill" viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z"/>
        </svg>
      </i>
    </h1>
    
  </div>
</template>

<script>
import Splide from '@splidejs/splide'
import '@splidejs/splide/dist/css/splide.min.css'
import { Grid } from '@splidejs/splide-extension-grid'
export default {
  name: 'Homepage',
  props: {
    msg: String
  },
  methods: {
    bottomNavbar: function () {
      var navbar = document.getElementById('nav')
      navbar.classList.add('bottom')
    },
    toggleVideo: function () {
      this.$root.bgvideo = !this.$root.bgvideo
    }
  },
  mounted () {
    this.bottomNavbar()
    const splide = new Splide('.splide', {
      height: '85vh',
      type: 'slide',
      drag: true,
      snap: true,
      focus: 'center',
      rewind: 'false',
      perPage:1,
      perMove:1,
      clones: 0,
      arrows: false,
      pagination: true,
      grid: {
       /*  rows: 4,
        cols: 3, */
        dimensions: [ [ 1, 1 ], [ 4, 3 ] ],
        gap: {
          row: '1rem',
          col: '1rem'
        }
      }
    })
    splide.mount({ Grid })
  }
  ,
  beforeUnmount () {
    this.$root.bgvideo = false
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  font-size: 8vmin;
  font-style: italic;
  text-shadow: 2px 2px 6px #e3e3e366;
  margin: 0;
  z-index: -1;
}
 
@media (max-width: 600px) {

  .hello h1{
    font-size: 14vmin;
    padding: 1rem;
  }
}

</style>
