<template>
    <div id="navbar-mobile" class="navbar">
        <div class="menu">
            <img @click="goHome(),closeNavList()" class="fullname-logo" alt="Bestway Marketing Logo" title="Bestway Marketing" src="@/assets/Logo-BestwayMarketing.svg">
            <div id="nav-icon" @click="toggleMobileNav()">
                <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="3rem" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                </svg>
            </div>
        </div>
        <div id="nav-links" class="nav">
            <hr class="myline">
            <ul>
                <li>
                  <router-link @click="closeNavList" to="/" >
                    <i>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-fill" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="m8 3.293 6 6V13.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/>
                        <path fill-rule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/>
                      </svg>
                    </i> Home
                  </router-link>
                </li>
                <li>
                  <router-link @click="closeNavList" to="/inicio">
                        &nbsp;&nbsp;  Inicio
                  </router-link>
                </li>
                <li>
                  <router-link @click="closeNavList" to="/nosotros">
                    <i>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
                        <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                        <path fill-rule="evenodd" d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"/>
                        <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
                      </svg>
                    </i>
                    Nosotros
                  </router-link>
                </li>
                <li>
                  <!-- path changed from /teams to /departamentos on mobile -->
                  <router-link @click="closeNavList" to="/departamentos">
                    <i>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-briefcase-fill" viewBox="0 0 16 16">
                        <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v1.384l7.614 2.03a1.5 1.5 0 0 0 .772 0L16 5.884V4.5A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5z"/>
                        <path d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5V6.85L8.129 8.947a.5.5 0 0 1-.258 0L0 6.85v5.65z"/>
                      </svg>
                    </i>
                    Departamentos
                  </router-link>
                </li>
                <li>
                  <router-link @click="closeNavList" to="/contactos">
                    Contactos
                  </router-link>
                </li>
                <li>
                  <router-link @click="closeNavList" to="/presupuestos">
                    <i>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-journal-text" viewBox="0 0 16 16">
                        <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
                        <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"/>
                        <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"/>
                      </svg>
                    </i>
                    Presupuestos
                  </router-link>
                </li>
                <li>
                  <router-link @click="closeNavList" to="/servicios">
                    <i>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-journal-text" viewBox="0 0 16 16">
                        <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
                        <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"/>
                        <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"/>
                      </svg>
                    </i>
                    Servicios
                  </router-link>
                </li>
                <li>
                  <router-link @click="closeNavList" to="/blog">
                    <i>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-newspaper" viewBox="0 0 16 16">
                        <path d="M0 2.5A1.5 1.5 0 0 1 1.5 1h11A1.5 1.5 0 0 1 14 2.5v10.528c0 .3-.05.654-.238.972h.738a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 1 1 0v9a1.5 1.5 0 0 1-1.5 1.5H1.497A1.497 1.497 0 0 1 0 13.5v-11zM12 14c.37 0 .654-.211.853-.441.092-.106.147-.279.147-.531V2.5a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0-.5.5v11c0 .278.223.5.497.5H12z"/>
                        <path d="M2 3h10v2H2V3zm0 3h4v3H2V6zm0 4h4v1H2v-1zm0 2h4v1H2v-1zm5-6h2v1H7V6zm3 0h2v1h-2V6zM7 8h2v1H7V8zm3 0h2v1h-2V8zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1z"/>
                      </svg>
                    </i>
                    Blog
                  </router-link>
                </li>
            </ul>
            <hr class="myline">
        </div>
  </div>
  <div v-if="$route.path !='/'" class="logo-container">
      <img alt="Bestway Marketing logo" src="@/assets/Logo-BestwayMarketing.svg" class="logo">
  </div>
  <div id="nav" class="glass-container">
      <router-link to="/">
        <i class="navi">
          <img src="@/assets/home/icon_home.png" class="img-medium launcher-icon" alt="Sample 1">
                          <span class="icon-name ">
                            Home</span>
        </i>
      </router-link>
      <router-link @click="topNavbar" to="/inicio">
        <i class="navi">
          <img src="@/assets/home/icon_inicio2.png" class="img-medium launcher-icon" alt="Sample 1" style="filter: drop-shadow(2px 4px 3px #3339);">
                            <span class="icon-name ">
                              Inicio</span>
        </i>
      </router-link>
      <router-link @click="topNavbar" to="/nosotros">
        <i class="navi">
          <img src="@/assets/home/icon_nosotros.png" class="img-medium launcher-icon" alt="Sample 1">
                          <span class="icon-name ">
                            Nosotros</span>
        </i>
        
      </router-link>
      <!-- path changed from /teams to /departamentos -->
      <router-link @click="topNavbar" to="/departamentos" class="launcher-icon">
        <!-- <i>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-briefcase-fill" viewBox="0 0 16 16">
            <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v1.384l7.614 2.03a1.5 1.5 0 0 0 .772 0L16 5.884V4.5A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5z"/>
            <path d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5V6.85L8.129 8.947a.5.5 0 0 1-.258 0L0 6.85v5.65z"/>
          </svg>
        </i>
        Departamentos -->
        <i class="navi">
          <img src="@/assets/home/icon_departamentos2.png" class="img-medium launcher-icon" alt="Sample 1"/>
          <span class="icon-name ">
            Departamentos
          </span>

        </i>
      </router-link>
      <router-link @click="topNavbar" to="/contactos">
        <i class="navi">

          <img src="@/assets/home/icon_contacto2.png" class="img-medium launcher-icon" alt="Sample 1">
                            <span class="icon-name ">
                              Contacto</span>
        </i>
      </router-link>
      <router-link @click="topNavbar" to="/presupuestos">
        <i class="navi">
          <img src="@/assets/home/icon_presupuesto.png" class="img-medium launcher-icon" alt="Sample 1">
                          <span class="icon-name ">
                            Presupuestos</span>
        </i>
        
      </router-link>
      <router-link @click="topNavbar" to="/servicios">
        <i class="navi">
          <img src="@/assets/home/icon_dptos.png" class="img-medium launcher-icon" alt="Sample 1">
                            <span class="icon-name ">
                              Servicios</span>

        </i>
      </router-link>
      <router-link @click="topNavbar" to="/blog">
        <i class="navi">
          <img src="@/assets/home/icon_blog.png" class="img-medium launcher-icon" alt="Sample 1">
                          <span class="icon-name ">
                            Blog</span>
        </i>
        
      </router-link>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  methods: {
    topNavbar: function () {
      var navbar = document.getElementById('nav')
      navbar.classList.remove('bottom')
    },
    toggleMobileNav () {
      const togglerIcon = document.getElementById('nav-links')
      togglerIcon.classList.toggle('active')
    },
    closeNavList () {
      const togglerIcon = document.getElementById('nav-links')
      togglerIcon.classList.remove('active')
    },
    goHome () {
      this.$router.push('/')
    }
  }
}
</script>

<style>
#navbar-mobile {
    position: fixed;
    top: 0;
    left: 0;
    background-color: transparent;
    width: 100%;
    z-index: 1000;
}

#navbar-mobile, .menu{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    height: 5rem;
    box-sizing: border-box;
}
.menu{
    padding: 2%;
    width: 100%;
    height: 100%;
}
#navbar-mobile .nav{
    display: block;
    transition: all ease-in-out 0.3s;
    position: relative;
    height: 0;
    overflow: hidden;
    width: 100%;
}

#navbar-mobile .nav.active {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    background-color: #e3e3e3;
    padding: 5% 0;
    box-sizing: border-box;
    width: 100%;
    height: calc(100vh - 5rem);
    position: relative;
    top:0
}

#navbar-mobile .nav .myline{
    border: solid 2px #999;
    border-radius: 4px;
    width: 60%;
    max-width: 600px;
    margin: 0.25rem auto;
}

#navbar-mobile .nav ul{
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    list-style-type: none;
    padding: 0;
}

#navbar-mobile .nav li {
    margin: auto;
    padding: 3% 0;
    width: 80%;
    text-align: left;

}

#navbar-mobile .nav li:hover, #navbar-mobile .nav li:focus, #navbar-mobile .nav li:active {
    background-color: #3e3e3e;
}

#navbar-mobile .nav li:hover a, #navbar-mobile .nav li:focus a, #navbar-mobile .nav li:active a {
    color: #fff;
}

#navbar-mobile .nav a{
    color: #3e3e3e;
    font-size: 1.6rem;
    text-decoration: none;
    padding: 1rem;
    margin:1em;
}

#nav {
    display: none;
}
.nav-icon{
    margin: auto;
}

.navi{
  position: relative;
  display: inline-flex;
  flex-flow: column nowrap;
  align-items: center;
}

.fullname-logo{
  margin-left: auto;
  width: 80%;
  max-width: 540px;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

#nav .launcher-icon{
  height: 3rem;
  width: 3rem;
  filter: drop-shadow(2px 4px 3px #3339);
}

.icon-name{
  font-weight: 300;
  font-size: 0.8rem;
  transition: all ease-in-out 0.3s;
}
.icon-name:hover, .img-medium:hover ~ .icon-name{
  font-weight: 800;

}
.navi{
  transition: all ease-in-out 0.4s;
}
.navi:hover{
  transform: translateY(-1.5rem);
}

.navi .icon-name{
  position: absolute;
  bottom: 0;
  opacity: 0;
}

.navi:hover > .icon-name{  
  transform: translateY(1.5rem);
  opacity: 1;
}

@media (min-width: 600px) {
  #navbar-mobile{
        display: none;
  }

  #nav {
    display: block;
    position: absolute;
    top: 2%;
    right: 3%;
    z-index: 500;
    padding: 0.3rem;
    border: solid 1px #999;
    border-radius: 1rem;
    line-height: 2em;
    transition: all 1s ease-in-out;
    transform: translateY(0);
    width: max-content;
    max-width: 90%;
    box-sizing: border-box;
    box-shadow: 3px 3px 6px #3336;

  }

  #nav.bottom{
    transform: translateY(85vh) translateX(50%);
    right: 50%;
  }

  #nav a {
    font-weight: bold;
    color: #2c3e50;
    margin: 0.5em;
    padding: 0.5em;
  }

  #nav a:hover, #nav a:focus {
    transform: scale(1.2);
  }

  #nav a.router-link-exact-active {
    color: #42b983;
  }

}
</style>
