<template>
  <div class="main-container animated-background" id="main-container">
    <div class="container styled-scrollbars">
      <router-view/>
    </div>
    <div v-if="bgvideo" class="bg-video-container">
      <div class="logo-container">
        <img alt="Bestway Marketing logo" src="@/assets/Logo-BestwayMarketing.svg" class="logo">
      </div>
      <div class="close-vid-button" @click="this.$root.bgvideo = false">
        <span class="material-icons" style="margin: auto;">close</span>
      </div>
      <video id="bg-video"  controls height="300px" width="300px">
      <!-- <source srcset="@/assets/home-backgroundVideo.webm" type="video/webm"> -->
      <source src="@/assets/home-backgroundVideo.mp4"  type="video/mp4">
    </video>
    </div>
  </div>
  <Navigation/>
</template>

<script>
import Navigation from '@/components/Navigation.vue'

export default {
  data: function () {
    return {
      bgvideo: false
    }
  },
  components: {
    Navigation
  }
}
</script>

<style>

@-webkit-keyframes mainBackgroundAnimation {
    0%{background-position:0% 50%}
    20%{background-position:20% 60%}
    50%{background-position:50% 80%}
    70%{background-position:70% 40%}
    100%{background-position:100% 20%}
}
@-moz-keyframes mainBackgroundAnimation {
    0%{background-position:0% 50%}
    20%{background-position:20% 60%}
    50%{background-position:50% 80%}
    70%{background-position:70% 40%}
    100%{background-position:100% 20%}
}
@keyframes mainBackgroundAnimation {
    0%{background-position:0% 50%}
    20%{background-position:20% 60%}
    50%{background-position:50% 80%}
    70%{background-position:70% 40%}
    100%{background-position:100% 20%}
}

@-webkit-keyframes animatedShadow{
    0%{ filter: drop-shadow( -20px 0px 16px #eee );}
    20%{ filter: drop-shadow( -10px 0px 10px #eee );}
    50%{ filter: drop-shadow( 0px 0px 8px #eee );}
    75%{ filter: drop-shadow( 10px 0px 10px #eee );}
    100%{ filter: drop-shadow( 20px 0px 16px #eee );}
}
@-moz-keyframes animatedShadow{
    0%{ filter: drop-shadow( -20px 0px 16px #eee );}
    20%{ filter: drop-shadow( -10px 0px 10px #eee );}
    50%{ filter: drop-shadow( 0px 0px 8px #eee );}
    75%{ filter: drop-shadow( 10px 0px 10px #eee );}
    100%{ filter: drop-shadow( 20px 0px 16px #eee );}
}
@keyframes animatedShadow {
/*    0%{ filter: drop-shadow( -20px 0px 16px #eee );}
    20%{ filter: drop-shadow( -10px 0px 10px #eee );}
    50%{ filter: drop-shadow( 0px 0px 8px #eee );}
    75%{ filter: drop-shadow( 10px 0px 10px #eee );}
    100%{ filter: drop-shadow( 20px 0px 16px #eee );}
*/
  0%{ filter: drop-shadow( -12px 0px 10px #eee );}
  50%{ filter: drop-shadow( 0px 6px 6px #eee );}
  100%{ filter: drop-shadow( 12px 0px 10px #eee );}
}
html,body{
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
}

.animated-background {
    background: linear-gradient(150deg,#111D,#3336,#e3e3e3,#009a7e21,#009a7e99);
    background-size: 200% 150%;
    -webkit-animation: mainBackgroundAnimation 6s ease alternate infinite;
    -moz-animation: mainBackgroundAnimation 6s ease alternate infinite;
    animation: mainBackgroundAnimation 6s ease alternate infinite;
}

.table-background {
  background-image: url('~@/assets/img/nosotros_background_desk3.jpg');
  background-size: cover;
  background-position: center;
  animation: none;
  transition: ease-in-out 1s;
}

.table-background-zoom {
  background-size: 200%;
 /*  background-position: left; */
}

.animatedShadow{
  -webkit-animation: animatedShadow 2s ease-in-out alternate infinite;
  -moz-animation: animatedShadow 2s ease-in-out alternate infinite;
  animation: animatedShadow 2s ease-in-out alternate infinite;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  width: 100%;
  max-width: 100%;
}
/*
#nav {
  position: absolute;
  top: 2%;
  right: 3%;
  z-index: 500;
  padding: 1rem;
  border: solid 1px #999;
  border-radius: 1rem;
  line-height: 2em;
  transition: all 1s ease-in-out;
  transform: translateY(0);
  width: fit-content;

}

#nav.bottom{
  transform: translateY(85vh) translateX(50%);
  right: 50%;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
  margin: 0.5em;
  padding: 0.5em;
}

#nav a:hover, #nav a:focus {
  transform: scale(1.2);
}

#nav a.router-link-exact-active {
  color: #42b983;
}
*/
.main-container{
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #e3e3e3 ;
  overflow: hidden;
  /*
  Only in Nosotros
  background-image: url('~@/assets/table.jpg');
  */
}

.container{
  position: relative;
  top: 12%;
  width: 80%;
  height: 85%;
  max-width: 1400px;
  max-height: 85vh;
  background-color: transparent;
  margin: auto;
  overflow-y: auto ;
  z-index: 300;
  transition: all 0.5s ease;
  /* padding: 2%; */
  box-sizing: border-box;
}

.glass-container{
  background: linear-gradient(130deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.2));
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.bg-video-container, #bg-video{
  box-sizing: border-box;
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.logo-container{
  display: flex;
  position: absolute;
  height: 20%;
  width: max-content;
  max-width: 270px;
  max-height: 11vh;
  padding: 1rem;
  background: url('./assets/Logo-shape.svg') no-repeat;
  background-size: contain;
  z-index: 20;
  border-radius: 0 0 3em 0;
  box-sizing: border-box;
  filter: drop-shadow( -2px 4px 7px #555);
  transition: all ease-in-out 400ms;
}

.logo-container:hover{
  filter: drop-shadow( 2px 2px 0px #6662);
}

.logo-container .logo {
    max-width: 80%;
    height: 100%;
    object-position: top;
    object-fit: contain;
}

/* ====== CUSTOM SCROLLBAR ====== */

.styled-scrollbars {
  --scrollbar-foreground: #6669;
  --scrollbar-background: #e3e3e3;
  /* Foreground, Background */
  scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
}
.styled-scrollbars::-webkit-scrollbar {
  width: 6px; /* Mostly for vertical scrollbars */
  height: 6px; /* Mostly for horizontal scrollbars */
}
.styled-scrollbars::-webkit-scrollbar-thumb { /* Foreground */
  background: var(--scrollbar-foreground);
  border-radius: 1rem;
}
.styled-scrollbars::-webkit-scrollbar-track { /* Background */
  background: var(--scrollbar-background);
  border-radius: 1rem;
}

.close-vid-button {
  display: flex;
  background-color: white;
  height: 40px;
  width: 40px;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 200;
  border-radius: 50%;
  cursor: pointer;
}

@media (max-width: 600px) {
  .close-vid-button {
    top: 80px;
    z-index: 301;
  }

  .bg-video-container {
    z-index: 300;
  }

  .main-container {
    position: fixed;
  }

  .main-container .container{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: calc(100vh - 5rem);
    max-height: 100vh;
    border-radius: 2rem 2rem 0 0;
    margin: 0;
    top: 5rem;
  }

  .hello h1{
    font-size: 4rem;
    padding: 1rem;
  }
  .logo-container{
    display: none;
  }
}
</style>
