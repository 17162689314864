<template>
  <ServiciosMobile/>
  <ServiciosDesktop/>
</template>

<script>
import ServiciosMobile from '@/components/servicios/services_mobile.vue'
import ServiciosDesktop from '@/components/servicios/services_desktop.vue'

export default {
  name: 'Servicios',
  components: {
    ServiciosMobile,
    ServiciosDesktop
  }
}
</script>

<style>
  @media screen and (max-width: 600px) {
    .mobile {
      display: block;
    }
    .desktop {
      display: none;
    }
  }
</style>
