<template>
    <Budget/>
</template>

<script>
import Budget from '@/components/presupuestos/Budget.vue'
export default {
  name: 'Presupuestos',
  components: {
    Budget
  }
}
</script>

<style>

</style>
