<template>
    <section id="ig-feed">
        <h2 class="title align-center mbr-fonts-style display-2">Instagram Feed</h2>
        <div id="curator-feed-default-feed-layout"><a href="https://curator.io" target="_blank" class="crt-logo crt-tag">Powered by Curator.io</a></div>
    </section>
    <section class="socials-links">
        <div class="socials-container">
            <h3 class="title" >Síguenos</h3>
            <div class="icons">
                <a href="https://www.twitter.com/@bwaymarketing/" style="height: 4rem; color: #009a7e;">
                    <i class="social-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="95%" height="95%" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16">
                            <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                        </svg>
                    </i>
                </a>
                <a href="https://www.instagram.com/bestwaymarketing/" style="height: 4rem; color: #009a7e;">
                    <i class="social-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="95%" height="95%" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                            <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
                        </svg>
                    </i>
                </a>
                <a href="https://www.facebook.com/@bestwaymarketingvzla" style="height: 4rem; color: #009a7e;">
                    <i class="social-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="95%" height="95%" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                        </svg>
                    </i>
                </a>
                <a href="https://www.tiktok.com/@bestwaymarketingvzla" style="height: 4rem; color: #009a7e;">
                    <i class="social-icon">
                        <svg width="95%" height="95%" fill="currentColor" viewBox="0 0 417 481" xmlns="http://www.w3.org/2000/svg">
                            <path d="M364.993 103.389C361.753 101.715 358.599 99.879 355.543 97.8891C346.655 92.0137 338.507 85.0906 331.273 77.2691C313.173 56.5591 306.413 35.5491 303.923 20.8391H304.023C301.943 8.62913 302.803 0.729126 302.933 0.729126H220.493V319.509C220.493 323.789 220.493 328.019 220.313 332.199C220.313 332.719 220.263 333.199 220.233 333.759C220.233 333.989 220.233 334.229 220.183 334.469V334.649C219.314 346.087 215.647 357.136 209.506 366.824C203.364 376.512 194.936 384.543 184.963 390.209C174.568 396.123 162.812 399.225 150.853 399.209C112.443 399.209 81.3127 367.889 81.3127 329.209C81.3127 290.529 112.443 259.209 150.853 259.209C158.124 259.202 165.35 260.347 172.263 262.599L172.363 178.659C151.376 175.948 130.056 177.616 109.746 183.558C89.4369 189.499 70.5791 199.585 54.3627 213.179C40.1534 225.525 28.2075 240.256 19.0627 256.709C15.5827 262.709 2.45272 286.819 0.862715 325.949C-0.137285 348.159 6.53271 371.169 9.71271 380.679V380.879C11.7127 386.479 19.4627 405.589 32.0927 421.699C42.2771 434.622 54.3096 445.974 67.8027 455.389V455.189L68.0027 455.389C107.913 482.509 152.163 480.729 152.163 480.729C159.823 480.419 185.483 480.729 214.623 466.919C246.943 451.609 265.343 428.799 265.343 428.799C277.098 415.17 286.445 399.638 292.983 382.869C300.443 363.259 302.933 339.739 302.933 330.339V161.219C303.933 161.819 317.253 170.629 317.253 170.629C317.253 170.629 336.443 182.929 366.383 190.939C387.863 196.639 416.803 197.839 416.803 197.839V115.999C406.663 117.099 386.073 113.899 364.993 103.389V103.389Z"/>
                        </svg>
                    </i>
                </a>
            </div>
        </div>
    </section>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { curator } from '@/assets/js/bwm-instagram-feed.js'
export default {
  name: 'SocialsBlock',
  mounted () {
    const curatorFeed = curator
    curatorFeed()
  }
}
</script>

<style scoped>
#ig-feed{
    min-height: 70%;
    box-sizing: border-box;
    padding-top: 3%;
    background:  #0a9f7d;
    color: #333;
}

#ig-feed h2{
    color: #FFF;
    text-shadow: 2px 4px 4px #d940f5;
}

.socials-links{
    height: 30%;
    color: #0a9f7d;
    text-shadow: 1px 1px 2px #333d;
    padding: 3%;
    box-sizing: border-box;
}

.title{
    font-size: 2.5rem;
    margin: 3%;
}

.socials-container .title{
    margin-top: 0;
}

.icons{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    width: 50%;
    margin: auto;
}
.social-icon{
    height: 4rem;
    width: 4rem;
}
</style>
