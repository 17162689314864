<template>
  <section id="marketing">
    <div class="blockBudget">
      <div class="bg-overlay"></div>
      <div class="info">
        <p class="banner-title">
          Más de 3.5 billones de personas que utilizan el Marketing Digital en el mundo.
        </p>
        <p>No aplicarlo en tu marca, puede limitar tus oportunidades.</p>
        <button class="btn" style="cursor: pointer;">
          <router-link to="/presupuestos" style="text-decoration: none; color: inherit">
              <svg xmlns="http://www.w3.org/2000/svg" style="margin-right:1em;" width="1.2rem" height="1.2rem" fill="currentColor" class="bi bi-briefcase" viewBox="0 0 16 16">
                <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z"/>
              </svg>
              <i class="icon">
              </i>
              Presupuesto Online
          </router-link>
        </button>
      </div>
    </div>
    <div class="marketing-items">
      <div class="card-item">
          <div class="card-img iconbox">
            <svg xmlns="http://www.w3.org/2000/svg" width="95%" height="95%" fill="currentColor" class="bi bi-graph-up-arrow" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z"/>
            </svg>
          </div>
          <div class="card-block">
              <h4 class="card-title">Mercado</h4>
              <p class="card-text">Acercamiento de las marcas hacia el cliente y viceversa.&nbsp;</p>
          </div>
      </div>
      <div class="card-item">
          <div class="card-img iconbox">
            <svg xmlns="http://www.w3.org/2000/svg" width="95%" height="95%" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
            </svg>
          </div>
          <div class="card-block">
              <h4 class="card-title">Viralidad</h4>
              <p class="card-text">
                Un buen contenido tendrá la capacidad de reproducirse masivamente
                y hacerse tendencia en cuestión <br>de minutos u horas.
              </p>
          </div>
      </div>
      <div class="card-item">
          <div class="card-img iconbox">
            <svg xmlns="http://www.w3.org/2000/svg" width="95%" height="95%" fill="currentColor" class="bi bi-person-check" viewBox="0 0 16 16">
              <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
              <path fill-rule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
            </svg>
          </div>
          <div class="card-block">
              <h4 class="card-title">Fidelización</h4>
              <p class="card-text">
                El estar interactuando diariamente, nos da la posibilidad de fidelizar al cliente.
              </p>
          </div>
      </div>
    </div>
  </section>

</template>

<script>
export default {
  name: 'MarketingBlock'

}
</script>

<style>
.blockBudget p {
  font-size: 0.8em;
}

.banner-title {
  font-size: 42px !important;
  font-weight: 400;
  font-style: italic;
}

#marketing{
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  justify-content: space-around;
}

.bg-overlay{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #00000091 !important;
  opacity: 0.85;
  z-index: 1;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.blockBudget, .marketing-items{
  position: relative;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-evenly;
  align-content: center;
  overflow-y: auto ;
}

.blockBudget {
  height: 60vh;
}

.marketing-items {
  height: 40vh;
  padding: 5% 0;
}

.blockBudget {
  background: url('../../assets/img/inicio-marketing-presupuestos-audiencia.jpeg');
  background-repeat: no-repeat ;
  background-size: cover;
  background-position: center;
}

.blockBudget .btn {
  margin-top: 2%;
  padding: 1.25rem;
  border-radius: 1rem;
  background-color: #009a7e;
  color: #FFF;
  font-weight: bold;
  font-size: 1.2rem;
  border: none;
  box-shadow: 0px 0px 1px 4px #eee9;
  transition: all ease-in-out 150ms;
}

.blockBudget .btn:hover {
  background-color: #FFFd;
  color: #0a9f7d;
  box-shadow: 0px 0px 1px 4px #0a9f7d;
  border: solid 2px #333a;
  transform: scale(1.05) translateY(-0.5em);
}

.blockBudget .icon {
  height: 1.5rem;
  width: 1.5rem;
}

.blockBudget .info{
  position: relative;
  z-index: 20;
  color: #FFF;
  text-shadow: 1px 1px 3px #000a;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 1em;
  box-sizing: border-box;
}

.card-item{
  width: 40%;
  font-size: 1.3rem;
}

.marketing-items .card-item {
  width: 25%;
}

.marketing-items .card-text {
  font-size: 0.9em;
}

.card-img{
  border: solid 3px currentColor;
  border-radius: 50%;
  box-sizing: border-box;
  height: 10vh;
  width: 10vh;
  margin: auto;
  padding: 6%;
  background-color: #0a9f7ddd;
  color: #FFF;
  box-shadow: 1px 0 10px 1px #3333;
}

.card-title {
    font-size: 1.2em;
    margin: 3%;
}
.card-text{
  font-size: 1em;
  color: #666;
}
@media screen and (max-width: 600px) {
  .banner-title {
    font-size: 36px !important;
  }
  .blockBudget{
    width: 100%;
    height: 100vh;
  }
  .blockBudget .info {
    padding: 0;
  }
  #marketing {
    flex-flow: column;
    height: auto;
  }
  .marketing-items {
    width: 100%;
    height: auto;
  }
  .marketing-items .card-item {
    width: 80%;
  }
  .card-item {
    margin: 10px 0;
    width: 100%;
    font-size: 1rem;
  }
  .bg-overlay {
    background-color: rgba(0, 0, 0, 0.6);
  }
}
/*
@media screen and (max-width: 900px) {
    #marketing {
      flex-flow: column;
    }
    .blockBudget, .marketing-items {
      width: 100%;
    }
    .marketing-items {
      height: 100%;
    }
    .card-title {
      margin: 1%;
    }
    .card-text {
      font-size: 0.8rem;
    }
    .card-img {
      height: 8vh;
      width: 8vh;
    }
}
*/
@media screen and (max-width: 1250px) {
  .card-img {
    padding: 4%;
  }
}
@media screen and (min-width: 1400px) {
  .card-item {
    width: 80%;
  }
}
</style>
