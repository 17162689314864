<template>
    <div class="chat-msg-container" style="margin-bottom: 15px;">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    </div>
    <div class="chat-msg-container">
        Neque sem sollicitudin eu tellus faucibus sem. Eu sed posuere aliquam fusce elit in felis.
        Facilisis imperdiet morbi feugiat feugiat arcu erat vitae porta. Quis cras mauris diam blandit.
        Suscipit consequat varius arcu hac morbi ultrices eget.
    </div>
    <div class="timer-msg">
        <span class="material-icons" id="chat-history">history</span>
        <p>Hace 5 minutos</p>
    </div>
    <div class="chat-msg-altern">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    </div>
    <div class="timer-msg" style="justify-content: end;">
        <span class="material-icons" id="chat-history">history</span>
        <p style="margin-right: 30px">Hace 1 minuto</p>
    </div>
    <div class="chat-msg-container">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam diam egestas nunc ipsum.
    </div>
    <div class="timer-msg">
        <span class="material-icons" id="chat-history">history</span>
        <p>Ahora</p>
    </div>
</template>

<script>
export default {
  name: 'ChatOne'
}
</script>

<style></style>
