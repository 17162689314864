<template>
    <iframe src="https://blog.bestwaymarketing.net/" frameborder="0" height="95%" width="95%"></iframe>
</template>

<script>
export default {
  name: 'Blog'
}
</script>

<style>
iframe{
    border-radius: 1rem;
    box-sizing: border-box;
}
</style>
