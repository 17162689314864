import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Inicio from '../views/Inicio.vue'
import Nosotros from '../views/Nosotros.vue'
import Teams from '../views/Teams.vue'
import Presupuestos from '../views/Presupuestos.vue'
import Blog from '../views/Blog.vue'
import Servicios from '../views/Servicios.vue'
import Contactos from '../views/Contactos.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/inicio',
    name: 'Inicio',
    component: Inicio
  },
  {
    path: '/nosotros',
    name: 'Nosotros',
    component: Nosotros
  },
  {
    path: '/teams',
    name: 'Departamentos',
    component: Teams,
    children: [
      {
        path: '/departamentos',
        component: () => import('@/components/departamentos/TeamsHome.vue')
      },
      {
        path: '/creativos',
        component: () => import('@/components/departamentos/CreativeTeam.vue')
      },
      {
        path: '/social-media',
        component: () => import('@/components/departamentos/SocialMediaTeam.vue')
      },
      {
        path: '/mercadeo',
        component: () => import('@/components/departamentos/MarketingTeam.vue')
      },
      {
        path: '/tecnologias',
        component: () => import('@/components/departamentos/TechTeam.vue')
      },
      {
        path: '/studio',
        component: () => import('@/components/departamentos/BestwayStudio.vue')
      }
    ]
  },
  {
    path: '/servicios',
    name: 'Servicios',
    component: Servicios
  },
  {
    path: '/contactos',
    name: 'Contactos',
    component: Contactos
  },
  {
    path: '/presupuestos',
    name: 'Presupuestos',
    component: Presupuestos
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
