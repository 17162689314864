<template>
  <div class="team-container styled-scrollbars">
    <router-view/>
  </div>
  <TeamsNav/>
</template>

<script>
/*
import Creativos from '@/components/departamentos/CreativeTeam.vue'
import Mercadeo from '@/components/departamentos/MarketingTeam.vue'
import SocialMedia from '@/components/departamentos/SocialMediaTeam.vue'
import Tecnologias from '@/components/departamentos/TechTeam.vue' */
import TeamsNav from '@/components/departamentos/TeamsNav.vue'

export default {
  name: 'Teams',
  components: {
  /*  Creativos,
    Mercadeo,
    SocialMedia,
    Tecnologias, */
    TeamsNav
  },
  methods: {
    glassContainer: function () {
      var container = document.querySelector('.main-container .container')
      container.classList.add('glass-container')
    },
    unglassContainer: function () {
      var container = document.querySelector('.main-container .container')
      container.classList.remove('glass-container')
    }
  },

  mounted () {
    this.glassContainer()
  },
  beforeUnmount () {
    this.unglassContainer()
  }
}
</script>

<style>
.team-container{
  height: 90%;
  box-sizing: border-box;
  overflow-y: auto;
  scroll-behavior: smooth;
  scroll-snap-type: y ;
}

.team-container section{
  scroll-snap-align: start ;
  scroll-snap-stop: normal ;
}

.team-nav{
    position: absolute;
    bottom: 0;
    height: 10%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
}

.team-intro{
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background: linear-gradient(30deg, #dddd, transparent);
    background-size: cover;
    background-position: center;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-shadow: 1px 1px 1px #333;
    position: relative;
}

.team-intro .titles{
    position: relative;
    z-index: 2;
    width: 70%;
}

.team-intro h2.title{
    font-size: 8vmin;
    font-style: italic;
}

.bg-overlay{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #0004;
  opacity: 1;
  z-index: 1;
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
}

</style>
