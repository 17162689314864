<template>
  <div class="home">
    <div id="splide">
        <div class="splide">
            <div class="splide__track">
                    <ul class="splide__list">
                      
                        <li class="splide__slide">
                          <div class="block">
                            <img v-if="this.$root.bgvideo === false" 
                            alt="Bestway Marketing logo" 
                            src="@/assets/Logo-BestwayMarketing.svg" 
                            class="bwm-logo glass-container"
                            @click="this.$router.push('inicio')"
                            >
                            <Homepage />
                          </div>  
                        </li>
                        <li class="splide__slide launcher-icon" @click="this.$router.push('inicio')">
                          <img src="@/assets/home/icon_inicio2.png" class="img-medium launcher-icon" alt="Sample 1"><br>
                          <span class="icon-name ">
                            Inicio</span>
                        </li>
                        <li class="splide__slide launcher-icon" @click="this.$router.push('departamentos')">
                          <img src="@/assets/home/icon_departamentos2.png" class="img-medium launcher-icon" alt="Sample 1"><br>
                          <span class="icon-name ">
                            Departamentos</span>
                        </li>
                        
                        <li class="splide__slide launcher-icon" @click="this.$router.push('nosotros')">
                          <img src="@/assets/home/icon_nosotros.png" class="img-medium launcher-icon" alt="Sample 1"><br>
                          <span class="icon-name ">
                            Nosotros</span>
                        </li>
                        <li class="splide__slide launcher-icon" @click="this.$router.push('contactos')">
                          <img src="@/assets/home/icon_contacto2.png" class="img-medium launcher-icon" alt="Sample 1"><br>
                          <span class="icon-name ">
                            Contacto</span>
                        </li>
                        <li class="splide__slide launcher-icon" @click="this.$router.push('presupuestos')">
                          <img src="@/assets/home/icon_presupuesto.png" class="img-medium launcher-icon" alt="Sample 1"><br>
                          <span class="icon-name ">
                            Presupuestos</span>
                        </li>
                        <li class="splide__slide launcher-icon" @click="this.$router.push('servicios')">
                          <img src="@/assets/home/icon_dptos.png" class="img-medium launcher-icon" alt="Sample 1"><br>
                          <span class="icon-name ">
                            Servicios</span>
                        </li>
                        <li class="splide__slide launcher-icon" @click="this.$router.push('blog')">
                          <img src="@/assets/home/icon_blog.png" class="img-medium launcher-icon" alt="Sample 1"><br>
                          <span class="icon-name ">
                            Blog</span>
                        </li>
                        
                        <!-- <li class="splide__slide launcher-icon" @click="this.$router.push('guia-de-marca')">
                          <img src="@/assets/home/icon_Form-Guia-de-Marca.png" class="img-medium launcher-icon" alt="Sample 1"><br>
                          <span class="icon-name ">
                            Form. Guia de Marca</span>
                        </li>
                        <li class="splide__slide launcher-icon" @click="this.$router.push('identidad-marca')">
                          <img src="@/assets/home/icon_Form-identidad.png" class="img-medium launcher-icon" alt="Sample 1"><br>
                          <span class="icon-name ">
                            Form. Identidad de Marca</span>
                        </li> -->
                        <li class="splide__slide launcher-icon" @click="this.$router.push('newsletter')">
                          <img src="@/assets/home/icon_alerts.png" class="img-medium launcher-icon" alt="Sample 1"><br>
                          <span class="icon-name ">
                            Newsletter</span>
                        </li>
                    </ul>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Homepage from '@/components/Homepage.vue'

export default {
  name: 'Home',
  components: {
    Homepage
  }
}
</script>
<style scoped>
.bwm-logo{
  margin: 1% 3% 3% 3%;
  cursor: pointer;
  width: 60%;
  max-width: 800px;
  height: 25vh;
  max-height: 800px;
  filter: drop-shadow( 5px 5px 4px #3e3e3e66);
  border-radius: 3rem;
  padding: 4%;
  transition: all ease-in-out 300ms;
}
.bwm-logo:hover{
  background: rgba(255, 255, 255, 0.9);
  filter: drop-shadow( 2px 2px 2px #666);
  transform: scale(1.02);
}

.block{
  height: 90vh;
}
.img-medium{
  height: 5rem;
  width: 5rem;
  transition: all ease-in-out 0.3s;
}

.icon-name{
  font-weight: 300;
  font-size: 0.8rem;
}
.icon-name:hover, .img-medium:hover ~ .icon-name{
  font-weight: 800;
}
.launcher-icon{
  transform: scale(1);
  transition: all ease-in-out 0.3s;
  cursor: pointer;
}
.launcher-icon:hover{
  transform: scale(1.05);

}
</style>
<style>
  .splide__pagination{
    transform: translateY(-3.5rem);
  }
</style>
