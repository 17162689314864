<template>
    <!-- Desktop -->
  <div class="desktop">
    <!-- Windows-like Navigation -->
    <div class="windows-nav">
      <div class="back-services">
        <router-link to="/" style="text-decoration: none; color: white;">
          <span class="material-icons">arrow_back</span>
        </router-link>
        <p style="font-size: 20px; color: #f5f5f5; margin-left: 10px;">Servicios</p>
      </div>
      <div class="windows-buttons">
        <span class="material-icons" style="cursor: default; color: white;">minimize</span>
        <div style="width: 10px"></div>
        <span class="material-icons" style="color: white; padding-top: 5px; cursor: default;">close</span>
      </div>
    </div>
    <!-- Row division -->
    <div class="row-flex" style="margin-top: 40px; overflow: auto;">
      <div class="column-flex column-flex-height windows-column-small" style="justify-content: flex-start;">
        <div class="windows-user-info">
          <div class="windows-avatar"></div>
          <div style="margin-left: 10px; text-align: left;">
            <h4>Bestway Marketing</h4>
            <p>bestwaymarketing@email.com</p>
          </div>
        </div>
        <!-- Selectors -->
        <div class="windows-list">
          <button class="windows-tile" @click="selectSection(0)" id="creativos-tile">
            <span class="material-icons" style="margin-left: 20px">lightbulb</span>
            <p style="margin-right: auto; margin-left: 20px">Creativos</p>
          </button>
          <button class="windows-tile" @click="selectSection(1)" id="mercadeo-tile">
            <span class="material-icons" style="margin-left: 20px">trending_up</span>
            <p style="margin-right: auto; margin-left: 20px">Mercadeo</p>
          </button>
          <button class="windows-tile" @click="selectSection(2)" id="mercadeo-tile">
            <span class="material-icons" style="margin-left: 20px">devices</span>
            <p style="margin-right: auto; margin-left: 20px">Social Media</p>
          </button>
          <button class="windows-tile" @click="selectSection(3)" id="social-tile">
            <span class="material-icons" style="margin-left: 20px">group</span>
            <p style="margin-right: auto; margin-left: 20px">Tecnologías</p>
          </button>
          <button class="windows-tile" @click="selectSection(4)" id="tec-tile">
            <span class="material-icons" style="margin-left: 20px">camera</span>
            <p style="margin-right: auto; margin-left: 20px">Bestway Studio</p>
          </button>
        </div>
      </div>
      <div class="column-flex column-flex-height windows-column-large">
        <!-- Creativos -->
        <div class="windows-content" id="creativos">
          <h1 style="text-align: left;">Creativos</h1>
          <div class="row-flex" style="flex-wrap: wrap;">
            <div class="screen-mini"></div>
            <div class="column-flex windows-mini-info">
              <div style="height: 20px;"></div>
              <div class="windows-avatar-mini"></div>
              <h4>Bestway Studio</h4>
              <p>bestwaystudio@email.com</p>
            </div>
          </div>
          <div class="windows-inner-list styled-scrollbar">
            <!-- Diseño gráfico -->
            <router-link to="/creativos#graphic-design" style="text-decoration: none">
              <div class="windows-inner-tile" style="margin-top: 0">
                <span class="material-icons">design_services</span>
                  <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                    <h5>Diseño Gráfico</h5>
                    <p>Ir a página</p>
                  </div>
                <span class="material-icons" style="margin-right: 20px">arrow_forward_ios</span>
              </div>
            </router-link>
            <!-- Editorial -->
            <router-link to="/creativos#editorials" style="text-decoration: none">
              <div class="windows-inner-tile">
                <span class="material-icons">feed</span>
                  <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                    <h5>Editorial</h5>
                    <p>Ir a página</p>
                  </div>
                <span class="material-icons" style="margin-right: 20px">arrow_forward_ios</span>
              </div>
            </router-link>
            <!-- Ilustración -->
            <router-link to="/creativos#illustrations" style="text-decoration: none">
              <div class="windows-inner-tile">
                <span class="material-icons">palette</span>
                  <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                    <h5>Ilustración</h5>
                    <p>Ir a página</p>
                  </div>
                <span class="material-icons" style="margin-right: 20px">arrow_forward_ios</span>
              </div>
            </router-link>
            <!-- Branding -->
            <router-link to="/creativos#branding" style="text-decoration: none">
              <div class="windows-inner-tile">
                <span class="material-icons">drive_file_rename_outline</span>
                  <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                    <h5>Branding</h5>
                    <p>Ir a página</p>
                  </div>
                <span class="material-icons" style="margin-right: 20px">arrow_forward_ios</span>
              </div>
            </router-link>
          </div>
        </div>
        <!-- Mercadeo -->
        <div class="windows-content" id="mercadeo">
          <h1 style="text-align: left;">Mercadeo</h1>
          <div class="row-flex" style="flex-wrap: wrap;">
            <div class="screen-mini" id="mercadeo-banner"></div>
            <div class="column-flex windows-mini-info">
              <div style="height: 20px;"></div>
              <div class="windows-avatar-mini"></div>
              <h4>Bestway Marketing</h4>
              <p>bestwaymarketing@email.com</p>
            </div>
          </div>
          <div class="windows-inner-list styled-scrollbar">
            <!-- Asesorías -->
            <router-link to="/mercadeo" style="text-decoration: none">
              <div class="windows-inner-tile" style="margin-top: 0">
                <span class="material-icons">support_agent</span>
                  <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                    <h5>Asesorías</h5>
                    <p>Ir a página</p>
                  </div>
                <span class="material-icons" style="margin-right: 20px">arrow_forward_ios</span>
              </div>
            </router-link>
            <!-- Intercambios -->
            <router-link to="/mercadeo" style="text-decoration: none">
              <div class="windows-inner-tile">
                <span class="material-icons">handshake</span>
                  <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                    <h5>Intercambios</h5>
                    <p>Ir a página</p>
                  </div>
                <span class="material-icons" style="margin-right: 20px">arrow_forward_ios</span>
              </div>
            </router-link>
            <!-- Material P.O.P -->
            <router-link to="/mercadeo" style="text-decoration: none">
              <div class="windows-inner-tile">
                <span class="material-icons">branding_watermark</span>
                  <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                    <h5>Material P.O.P</h5>
                    <p>Ir a página</p>
                  </div>
                <span class="material-icons" style="margin-right: 20px">arrow_forward_ios</span>
              </div>
            </router-link>
            <!-- Litografía -->
            <router-link to="/mercadeo" style="text-decoration: none">
              <div class="windows-inner-tile">
                <span class="material-icons">brush</span>
                  <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                    <h5>Litografía</h5>
                    <p>Ir a página</p>
                  </div>
                <span class="material-icons" style="margin-right: 20px">arrow_forward_ios</span>
              </div>
            </router-link>
            <!-- Publicidad Exterior -->
            <router-link to="/mercadeo" style="text-decoration: none">
              <div class="windows-inner-tile">
                <span class="material-icons">travel_explore</span>
                  <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                    <h5>Publicidad Exterior</h5>
                    <p>Ir a página</p>
                  </div>
                <span class="material-icons" style="margin-right: 20px">arrow_forward_ios</span>
              </div>
            </router-link>
            <!-- Eventos -->
            <router-link to="/mercadeo" style="text-decoration: none">
              <div class="windows-inner-tile">
                <span class="material-icons">celebration</span>
                  <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                    <h5>Eventos</h5>
                    <p>Ir a página</p>
                  </div>
                <span class="material-icons" style="margin-right: 20px">arrow_forward_ios</span>
              </div>
            </router-link>
            <!-- Promotores -->
            <router-link to="/mercadeo" style="text-decoration: none">
              <div class="windows-inner-tile">
                <span class="material-icons">badge</span>
                  <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                    <h5>Promotores</h5>
                    <p>Ir a página</p>
                  </div>
                <span class="material-icons" style="margin-right: 20px">arrow_forward_ios</span>
              </div>
            </router-link>
          </div>
        </div>
        <!-- Social Media -->
        <div class="windows-content" id="social">
          <h1 style="text-align: left;">Social Media</h1>
          <div class="row-flex" style="flex-wrap: wrap;">
            <div class="screen-mini" id="social-banner"></div>
            <div class="column-flex windows-mini-info">
              <div style="height: 20px;"></div>
              <div class="windows-avatar-mini"></div>
              <h4>Bestway Marketing</h4>
              <p>bestwaymarketing@email.com</p>
            </div>
          </div>
          <div class="windows-inner-list styled-scrollbar">
            <!-- Audiencia -->
            <router-link to="/social-media" style="text-decoration: none">
              <div class="windows-inner-tile" style="margin-top: 0">
                <span class="material-icons">groups</span>
                  <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                    <h5>Audiencia</h5>
                    <p>Ir a página</p>
                  </div>
                <span class="material-icons" style="margin-right: 20px">arrow_forward_ios</span>
              </div>
            </router-link>
            <!-- Portafolio -->
            <router-link to="/social-media" style="text-decoration: none">
              <div class="windows-inner-tile">
                <span class="material-icons">grid_view</span>
                  <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                    <h5>Portafolio</h5>
                    <p>Ir a página</p>
                  </div>
                <span class="material-icons" style="margin-right: 20px">arrow_forward_ios</span>
              </div>
            </router-link>
            <!-- Objetivos -->
            <router-link to="/social-media" style="text-decoration: none">
              <div class="windows-inner-tile">
                <span class="material-icons">assignment</span>
                  <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                    <h5>Objetivos</h5>
                    <p>Ir a página</p>
                  </div>
                <span class="material-icons" style="margin-right: 20px">arrow_forward_ios</span>
              </div>
            </router-link>
          </div>
        </div>
        <!-- Tecnologias -->
        <div class="windows-content" id="tecnologias">
          <h1 style="text-align: left;">Tecnologías</h1>
          <div class="row-flex" style="flex-wrap: wrap;">
            <div class="screen-mini" id="tecnologias-banner"></div>
            <div class="column-flex windows-mini-info">
              <div style="height: 20px;"></div>
              <div class="windows-avatar-mini"></div>
              <h4>Bestway Marketing</h4>
              <p>bestwaymarketing@email.com</p>
            </div>
          </div>
          <div class="windows-inner-list styled-scrollbar">
            <!-- Proyectos -->
            <router-link to="/tecnologias" style="text-decoration: none">
              <div class="windows-inner-tile" style="margin-top: 0">
                <span class="material-icons">developer_mode</span>
                  <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                    <h5>Proyectos</h5>
                    <p>Ir a página</p>
                  </div>
                <span class="material-icons" style="margin-right: 20px">arrow_forward_ios</span>
              </div>
            </router-link>
            <!-- Desarrollo Web -->
            <router-link to="/tecnologias" style="text-decoration: none">
              <div class="windows-inner-tile">
                <span class="material-icons">tab</span>
                  <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                    <h5>Desarrollo Web</h5>
                    <p>Ir a página</p>
                  </div>
                <span class="material-icons" style="margin-right: 20px">arrow_forward_ios</span>
              </div>
            </router-link>
            <!-- SEO -->
            <router-link to="/tecnologias" style="text-decoration: none">
              <div class="windows-inner-tile">
                <span class="material-icons">language</span>
                  <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                    <h5>SEO</h5>
                    <p>Ir a página</p>
                  </div>
                <span class="material-icons" style="margin-right: 20px">arrow_forward_ios</span>
              </div>
            </router-link>
          </div>
        </div>
        <!-- Studio -->
        <div class="windows-content" id="studio">
          <h1 style="text-align: left;">Bestway Studio</h1>
          <div class="row-flex" style="flex-wrap: wrap;">
            <div class="screen-mini" id="studio-banner"></div>
            <div class="column-flex windows-mini-info">
              <div style="height: 20px;"></div>
              <div class="windows-avatar-mini"></div>
              <h4 style="margin-bottom: 0;">Bestway Studio</h4>
              <p>bestwaystudio@email.com</p>
            </div>
          </div>
          <div class="windows-inner-list styled-scrollbar">
            <!-- Audiovisual -->
            <router-link to="/studio#audiovisuals" style="text-decoration: none">
              <div class="windows-inner-tile">
                <span class="material-icons">videocam</span>
                  <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                    <h5>Audiovisual</h5>
                    <p>Ir a página</p>
                  </div>
                <span class="material-icons" style="margin-right: 20px">arrow_forward_ios</span>
              </div>
            </router-link>
            <!-- Bestway Photography -->
            <router-link to="/studio#photography" style="text-decoration: none">
              <div class="windows-inner-tile">
                <span class="material-icons">photo_camera</span>
                  <div class="column-flex" style="margin-right: auto; margin-left: 20px">
                    <h5>Bestway Photography</h5>
                    <p style="margin-bottom: 0">Ir a página</p>
                  </div>
                <span class="material-icons" style="margin-right: 20px">arrow_forward_ios</span>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServiciosDesktop',
  methods: {
    selectSection: function (index) {
      var content = document.getElementsByClassName('windows-content')
      switch (index) {
        case 0:
          console.log('Selected index: 0')
          content[0].style.display = 'flex'
          content[1].style.display = 'none'
          content[2].style.display = 'none'
          content[3].style.display = 'none'
          content[4].style.display = 'none'
          break
        case 1:
          console.log('Selected index: 1')
          content[0].style.display = 'none'
          content[1].style.display = 'flex'
          content[2].style.display = 'none'
          content[3].style.display = 'none'
          content[4].style.display = 'none'
          break
        case 2:
          console.log('Selected index: 2')
          content[0].style.display = 'none'
          content[1].style.display = 'none'
          content[2].style.display = 'flex'
          content[3].style.display = 'none'
          content[4].style.display = 'none'
          break
        case 3:
          console.log('Selected index: 3')
          content[0].style.display = 'none'
          content[1].style.display = 'none'
          content[2].style.display = 'none'
          content[3].style.display = 'flex'
          content[4].style.display = 'none'
          break
        case 4:
          console.log('Selected index: 4')
          content[0].style.display = 'none'
          content[1].style.display = 'none'
          content[2].style.display = 'none'
          content[3].style.display = 'none'
          content[4].style.display = 'flex'
          break
        default:
          console.log('Default index: ' + index)
          content[0].style.display = 'flex'
          break
      }
    },
    onLoadItem: function () {
      document.getElementById('creativos-tile').focus()
    }
  },
  mounted () {
    this.onLoadItem()
  }
}
</script>

<style>
  .desktop ::-webkit-scrollbar {
    width: 5px;
  }
  .desktop ::-webkit-scrollbar-track {
    background: rgba(245, 245, 245, 0.1);
    border-radius: 20px;
  }
  .desktop ::-webkit-scrollbar-thumb {
    background: rgba(40, 40, 40, 0.5);
    border-radius: 20px;
  }
  .desktop ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(40, 40, 40, 0.8);
  }
  .column-flex {
    display: flex;
    flex-direction: column;
  }
  .mobile {
    display: none;
  }
  .desktop {
    height: 100%;
    width: 100%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 20px;
    background-image: linear-gradient(135deg,#3336, #e3e3e333); 
    flex-direction: column;
  }
  .windows-column-large {
    width: 65%;
    min-width: 600px;
  }
  .windows-column-small {
    width: 35%;
    min-width: 360px;
  }
  .windows-nav {
    height: 50px;
    /*width: 100%;*/
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
  }
  .windows-user-info {
    height: 140px;
    padding: 0 20px;
    color: #f5f5f5;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .windows-user-info h4 {
    margin: 0;
    margin-bottom: 5px;
  }
  .windows-user-info p {
    font-size: 12px;
    margin: 0;
    margin-top: 2px;
  }
  .windows-avatar {
    background-color: #282828;
    background-image: url('../../assets/logo-bw-white.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 50%;
    min-height: 120px;
    min-width: 120px;
  }
  .windows-avatar-mini {
    background-color: #f5f5f5;
    background-image: url('../../assets/Logo-BestwayMarketing.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 50%;
    min-height: 80px;
    width: 80px;
    margin-top: auto;
    margin-bottom: 5%;
  }
  .windows-list {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 20px;
  }
  .windows-tile {
    color: #f5f5f5;
    height: 45px;
    width: 300px;
    border-radius: 5px;
    margin: 10px 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    outline: none;
  }
  .windows-tile:focus {
    border-left: 5px solid #f5f5f5;
    background-color: rgba(0, 0, 0, 0.2);
  }
  .windows-tile p {
    font-size: 20px;
  }
  #social, #mercadeo, #tecnologias, #studio {
    display: none;
  }
  #mercadeo-banner {
    background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url('../../assets/img/team-mercadeo-banner_intro.jpg');
  }
  #social-banner {
    background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url('../../assets/img/team-social_media-banner_intro.jpg');
  }
  #tecnologias-banner {
    background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url('../../assets/img/team-tech-banner_intro.jpg');
  }
  #studio-banner {
    background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url('../../assets/img/BANNER BWS SMALL.jpg');
  }
  .windows-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: #f5f5f5;
    padding: 0 20px;
    margin-right: 20px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .windows-content h1 {
    margin-bottom: 20px;
  }
  .windows-mini-info {
    margin-left: 20px;
    margin-right: auto;
    margin-top: auto;
    flex-direction: column;
  }
  .windows-mini-info h4{
    text-align: left;
    margin: 0;
  }
  .windows-mini-info p {
    margin: 0;
  }
  .windows-inner-list {
    margin-top: 30px;
  }
  .windows-inner-tile {
    height: 50px;
    width: 100%;
    border-radius: 5px;
    background-image: linear-gradient(90deg, rgba(245, 245, 245, 0.8), rgba(245, 245, 245, 0.4));
    color: #282828;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
  }
  .windows-inner-tile p {
    margin: 2px 0;
    font-size: 12px;
  }
  .windows-inner-tile .material-icons {
    margin-left: 20px;
    margin-right: 5px;
    color: #282828;
  }
  .screen-mini {
    height: 230px;
    min-width: 350px;
    background-color: #f5f5f5;
    background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url('../../assets/img/team-creativos-banner_intro.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
  }
  .back-services {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .windows-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
</style>
